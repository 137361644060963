.tags {
	border-radius: var(--border-radius-lg);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-lg);

	.tagsList {
		display: flex;
		flex-wrap: wrap;
		gap: var(--space-inset-sm);
		margin: 0;
		padding: 0;
		list-style: none;

		.tag {
			border-radius: var(--border-radius-4xl);
			background-color: white;
			padding: var(--space-inset-2xs) var(--space-inset-sm);
			font-size: var(--font-size-small);
		}
	}
}
