.integratorCard {
	display: flex;
	row-gap: var(--space-inline-sm);
	flex-direction: column;
	cursor: pointer;
}
.integratorLogo {
	aspect-ratio: 16 / 9;
	width: 100%;
	height: auto;
	object-fit: contain;
}
.integratorContactIcon {
	display: flex;
	gap: var(--space-stack-sm);
	margin-bottom: var(--space-stack-xs);
}
