@use 'src/styles/variables' as variables;

.panel {
	margin-bottom: var(--space-stack-md);
	max-width: 60rem;
	scroll-margin-top: 8rem;

	@media (min-width: variables.$breakpoint-md) {
		scroll-margin-top: 10rem;
	}

	@media (min-width: variables.$breakpoint-xl) {
		scroll-margin-top: 6rem;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		.headerFlexBox {
			display: flex;
			flex: flex-wrap;
			align-content: center;
			justify-content: space-between;
		}

		.icon {
			float: right;
			transition: all 0.3s ease;

			&.iconOpen {
				transform: rotate(180deg);
			}
		}
	}

	.content {
		opacity: 0;
		transition: all 0.5s ease;
		padding: 0 var(--space-inset-xl);
		height: 0;
		max-height: 0;
		overflow: hidden;

		&.open {
			opacity: 1;
			border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
			padding: var(--space-inset-xl);
			height: auto;
			max-height: 100%;
		}
	}

	&.compact {
		margin-bottom: 0;
		border: none;
		border-bottom: 1px solid var(--line-color);
		.header {
			border-radius: 0;
			padding: var(--space-inset-lg) 0;
		}
		.content {
			border-top: 0;
			border-radius: 0;
			padding: 0 var(--space-inset-2xl) 0 0;

			&.open {
				padding: 0 var(--space-inset-2xl) var(--space-inset-lg) 0;
			}
		}
	}
}
