.internalVersionTag {
	display: flex;
	position: absolute;
	right: 0;
	left: 0;
	justify-content: flex-start;
	align-items: center;
	margin-top: -2px;
	height: 1.5rem;
	font-size: 0.8rem;

	&:hover {
		button,
		input {
			color: var(--text-light);
		}
		input {
			font-size: 1rem;
		}
	}

	button {
		color: rgba(255, 255, 255, 0.5);
	}

	input {
		transition: font-size 0.05s ease-in;
		border: 0;
		background: transparent;
		color: rgba(255, 255, 255, 0.3);
	}
}
