.container {
	margin-bottom: var(--space-inset-xl);
}

.linksContainer {
	display: flex;
	flex-direction: column;
	gap: var(--space-inset-sm);
	padding-left: var(--space-inset-md);
}

.filterSection {
	margin-bottom: var(--space-stack-lg);
}
