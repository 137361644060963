@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.section {
	display: flex;

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
	}

	.cardAnimation {
		opacity: 1;
		animation: fadeIn 0.4s ease-in;
	}
}
