@use 'src/styles/variables' as variables;

.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: var(--z-index-1);

	a {
		margin-right: 0;
	}

	&.padding {
		padding: var(--space-inset-3xl) var(--space-inset-xl);

		@media (max-width: variables.$breakpoint-xl) {
			padding: var(--space-inset-xl);
		}
	}
}

.image {
	position: relative;
	margin-bottom: var(--space-stack-xl);
	width: 100%;
	max-width: 20.625rem;
	height: 9.375rem;
}

.textLayout--center {
	text-align: center;

	.buttonWrapper {
		justify-content: center;
	}
}

.textLayout--left {
	text-align: left;
}

.imageTextContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.buttonWrapper {
	display: flex;
	flex: 1;
	align-items: flex-end;
	margin-top: var(--space-stack-2xl);

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: var(--space-stack-xl);
	}
}

.cardLink {
	cursor: pointer;
}
