@use 'src/styles/variables' as variables;

.header.header.header {
	position: relative;
	z-index: var(--z-index-1);

	@media screen and (max-width: variables.$breakpoint-md) {
		align-items: flex-start;
		text-align: left;
	}
}

.buttonPosition {
	margin-top: 8rem;
	text-align: center;
}
