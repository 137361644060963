@use 'src/styles/variables' as variables;

.bgColor {
	background-color: var(--bg-white);
}

.titleColor {
	--title-color: var(--text-dark);
	color: var(--title-color);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--text-dark);
}

.iconColor {
	--icon-color: var(--text-dark);
	color: var(--icon-color);
}

.textLink {
	color: var(--text-dark);

	&:hover {
		color: var(--primary-green);
	}

	&:hover .markLink {
		border-bottom-color: var(--primary-yellow);
	}

	a {
		color: var(--text-dark);
	}
}
