.buttonIcon {
	vertical-align: middle;
	margin-right: var(--space-inline-xs);
	margin-bottom: var(--space-stack-3xs);
}
.closeIcon {
	position: absolute;
	top: -2.5rem;
	right: 0;
	z-index: var(--z-index-max);
}
.overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-max);
	animation: fadeIn ease 0.3s;
	background: rgba(248, 241, 232, 0.75);
	width: 100vw;
	height: 100vh;
	overflow-y: hidden;
}

.showVideo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.videoWrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto;
	padding: var(--space-stack-md);
	width: 100%;
	max-width: 60rem;
}
