@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.container {
	width: 100%;

	.uspContainer {
		padding-top: var(--space-inset-3xl);
	}

	&.hasSubMenuV1 {
		.quoteContainer {
			.bigQuote {
				font-size: 3.5rem;
			}
		}
	}

	&.theme-white {
		.title {
			opacity: 0.8;
		}

		.uspContainer {
			border-top: 1px solid var(--line-color);
		}
	}

	&.theme-dark-green {
		.title {
			opacity: 0.8;
		}

		.uspContainer {
			border-top: 1px solid var(--text-yellow);
		}
	}

	.quote {
		margin-bottom: var(--space-stack-xl);

		&::before {
			content: '\201C';
		}

		&::after {
			content: '\201D';
		}
	}

	.quoteRichtext p {
		margin-bottom: var(--space-stack-xl);

		&::before {
			content: '\201C';
		}

		&::after {
			content: '\201D';
		}
	}

	.quoteGrid {
		column-gap: 6rem;
		row-gap: 6rem;
		margin-bottom: var(--space-stack-xl);
	}

	.quoteContainer {
		display: flex;
		flex-wrap: wrap;
		height: 100%;

		.bigQuote {
			opacity: 1;
			margin-bottom: var(--space-stack-xl);
			font-weight: 900;
			font-size: functions.rem(66px);
			line-height: functions.rem(80px);
			font-family: var(--font-family-heavitas);
			text-align: left;
			text-transform: lowercase;

			@media (max-width: variables.$breakpoint-xl) {
				font-size: var(--font-size-h1);
				line-height: 1.5;
			}

			@media (max-width: variables.$breakpoint-md) {
				font-size: var(--font-size-h3);
				line-height: 1.5;
			}
		}
	}

	.singleTestimonialContainer {
		flex-flow: row nowrap;
		gap: 2rem;
	}

	.testimonialTextContainer {
		display: flex;
		flex-direction: column;
	}

	.imageTestimonialContainer {
		position: relative;
		min-width: 26rem;
		min-height: 40rem;
	}

	.noImageTextContainer {
		margin-bottom: var(--space-stack-xl);
		width: 100%;
	}

	.longTextAlign {
		align-self: center;
	}

	.flexWrapper {
		display: flex;
		flex-direction: column;
		align-self: flex-end;
		width: 100%;
	}
}

.title {
	opacity: 0.8;
	margin-top: var(--space-stack-xs);
	margin-bottom: 0;
}

.button {
	margin-bottom: var(--space-stack-xl);
}

.image {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 25rem;
}

@media (max-width: variables.$breakpoint-xl) {
	.container {
		.articleTestimonial {
			column-gap: 2rem;
			row-gap: 2rem;
		}

		.singleTestimonialContainer {
			gap: 2rem;
		}

		.imageTestimonialContainer {
			min-width: 50%;
			min-height: var(--image-min-height-md);
		}

		.testimonialTextContainer {
			width: 50%;
		}
	}

	.imageContainer {
		order: 1;
	}

	.articleContainer {
		order: 2;
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		.imageTestimonialContainer {
			max-height: var(--image-min-height-md);
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.image {
		display: none;
	}

	.container {
		.singleTestimonialContainer {
			flex-direction: column-reverse;
		}

		.imageTestimonialContainer {
			display: none;
		}

		.testimonialTextContainer {
			width: 100%;
		}
	}
}
