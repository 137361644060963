.imageContainer {
	position: relative;
	float: right;
	padding: 0 0 4rem 4rem;
	min-width: 50%;
	max-width: 50%;
}

@media (max-width: 48rem) {
	.imageContainer {
		float: none;
		padding: 2rem 0;
		max-width: 100%;
	}
}
