.sectionNav {
	position: -webkit-sticky;
	position: sticky;
	top: 2.5rem;
	transition: all 0.5s;
	padding: var(--space-inset-3xl);
	padding-left: 0;

	@media (max-width: 36rem) {
		position: fixed;
		top: 5rem;
		z-index: 200;
		background-color: var(--sidenav-green);
		padding: 0;
		width: 100%;
	}
}
