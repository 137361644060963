@use 'src/styles/variables' as variables;

.container {
	--backgroundImageHeight: 17rem;
	z-index: var(--z-index-1);

	@media (max-width: variables.$breakpoint-lg) {
		--backgroundImageHeight: 30rem;
	}
	@media (max-width: variables.$breakpoint-md) {
		--backgroundImageHeight: 16rem;
	}

	.image {
		position: absolute;
		z-index: -1;

		@media (max-width: variables.$breakpoint-lg) {
			display: block;
			height: var(--backgroundImageHeight);
		}
	}

	&.layout-right {
		.image {
			right: 0;
			left: auto;
			width: 50vw;

			@media (max-width: variables.$breakpoint-lg) {
				width: 100vw;
			}
		}
		.section {
			display: flex;
			flex-wrap: wrap;
		}

		.titleContainer {
			margin-bottom: var(--space-stack-3xl);

			@media (max-width: variables.$breakpoint-lg) {
				margin-top: var(--backgroundImageHeight);
				text-align: center;
				.brandline {
					margin: 0 auto;
					margin-bottom: var(--space-stack-xl);
				}
			}
		}

		.loanContainer {
			@media (max-width: variables.$breakpoint-lg) {
				margin-bottom: var(--space-stack-2xl);
			}
		}

		.leftColumn {
			width: 100%;
			.buttonContainer {
				margin: 0 auto;
				width: auto;
			}
			@media (min-width: variables.$breakpoint-lg) {
				padding: 0 var(--space-inset-5xl);
				width: 50%;
			}
			.mobile {
				display: none;
				@media (max-width: variables.$breakpoint-lg) {
					display: block;
				}
				.gridItem {
					display: flex;
					justify-content: center;
				}
			}
		}
		.rightColumn {
			display: none;
			padding: var(--space-inset-xl);
			width: 100%;
			@media (min-width: variables.$breakpoint-lg) {
				display: block;
				width: 50%;
			}
		}
	}

	&.layout-full {
		@media (min-width: variables.$breakpoint-lg) {
			background: none;
		}

		.section {
			@media (max-width: variables.$breakpoint-lg) {
				padding-top: calc(var(--backgroundImageHeight) + var(--space-stack-2xl));
			}
		}

		.leftColumn {
			.titleContainer {
				margin: 0 auto;
				margin-top: var(--backgroundImageHeight) - var(--space-stack-3xl);
				margin-bottom: var(--space-stack-2xl);
				width: 57rem;
				max-width: 100%;
				text-align: center;

				.brandline {
					margin: 0 auto;
					margin-bottom: var(--space-stack-xl);
				}
			}
			.loanContainer {
				margin-bottom: var(--space-stack-2xl);
			}
			.buttonContainer {
				margin: 0 auto;
				width: auto;
				.desktop {
					@media (min-width: variables.$breakpoint-lg) {
						display: flex;
						justify-content: center;
					}
					.linkButton:last-child {
						margin-left: var(--space-stack-xl);
					}
				}
				.mobile {
					margin: 0 auto;
					width: 38.5rem;
					max-width: 100%;
				}
			}
		}
		.rightColumn {
			display: none;
		}
	}

	.buttonContainer {
		.mobile {
			@media (min-width: variables.$breakpoint-lg) {
				display: none;
			}
		}

		.desktop {
			@media (max-width: variables.$breakpoint-lg) {
				display: none;
			}
		}
	}
}
