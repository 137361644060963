@use 'src/styles/variables' as variables;

.container {
	@media (max-width: variables.$breakpoint-sm) {
		padding: 0 var(--space-inline-md);
	}
}

.gridItem {
	padding: var(--space-inline-3xl);

	@media (max-width: variables.$breakpoint-xl) {
		padding: var(--space-inline-xl);
	}

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inline-xl) 6rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: 0;
	}
}
