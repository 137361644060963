.card {
	width: 100%;
	height: 100%;
}

.list {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style-type: none;
}

.listItem {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: var(--space-stack-xs);
	width: 100%;

	.iconContainer {
		width: 10%;
	}
	.text {
		width: 80%;
	}
}
