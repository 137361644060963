@use 'src/styles/variables' as variables;

.size--medium {
	@media (min-width: variables.$breakpoint-xl) {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.icon {
		margin-right: var(--space-inline-md);
	}

	.playIcon {
		width: 1.5rem;
		min-width: 1.5rem;

		@media (max-width: variables.$breakpoint-md) {
			width: 2rem;
			min-width: 2rem;
			height: 1.85rem;
		}
	}
}

.size--large {
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon {
		margin-bottom: 1.25rem;
		width: 3.5rem;
		height: 3.5rem;
	}
}
