@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

$price-card-group-offset: 6rem;

.backgroundPattern {
	width: 100%;
}

.backgroundImage.backgroundImage.backgroundImage {
	&.withBackgroundPattern {
		max-height: 70rem;

		@media (max-width: variables.$breakpoint-xl) {
			max-height: 65rem;
		}

		@media (max-width: variables.$breakpoint-md) {
			position: absolute;
			max-height: 45rem;
		}
	}
}

.text {
	@media (min-width: variables.$breakpoint-xl) {
		max-width: 45%;
	}
	@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-xl) {
		padding: 0 var(--space-inset-2xl);
	}
}

.section.section.section {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	padding-top: var(--space-inset-5xl);

	&.menuVersionV3 {
		padding-top: var(--space-inset-7xl);
	}
}

.inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: center;

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-md);
	}

	@media (max-width: variables.$breakpoint-sm) {
		padding: 0 var(--space-inset-xs);
	}
}

.title {
	margin-bottom: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: 2.5rem;
	}
}

.foregroundImage {
	position: relative;
	margin-bottom: var(--space-stack-3xl);
	width: 86.625rem;
	min-height: 32.0625rem;

	@media (max-width: variables.$breakpoint-max) {
		width: calc(100% - var(--space-stack-3xl));
		min-height: 22rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		width: 100%;
		min-height: 11rem;
	}
}

.linkToggle {
	margin-bottom: var(--space-stack-3xl);
	@media (max-width: variables.$breakpoint-lg) {
		width: 70%;
	}
	@media (max-width: variables.$breakpoint-md) {
		width: 80%;
	}
	@media (max-width: variables.$breakpoint-sm) {
		width: 90%;
	}
	@media (max-width: variables.$breakpoint-sm) {
		width: 100%;
	}
	&.extraMarginBottom {
		margin-bottom: calc(8rem + $price-card-group-offset);
		@media (max-width: variables.$breakpoint-md) {
			margin-bottom: calc(2rem + $price-card-group-offset);
		}
	}
}

.priceCardGroup {
	&.marginTop {
		margin-top: -#{$price-card-group-offset};
	}

	&.marginBottom {
		margin-bottom: var(--space-stack-3xl);
	}
}

.containerFoldoutButton {
	display: flex;
	justify-content: center;
	width: 100%;
}
