@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	min-height: 30rem;

	&.multiColorBgPattern {
		background: url('/patterns/colorblock-pattern.svg');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	@media (min-width: variables.$breakpoint-lg) {
		height: calc(100vh - var(--global-menu-height));
		min-height: 50rem;
		max-height: 65rem;
	}

	.buttonContainer {
		display: flex;
		justify-content: center;
		margin-top: var(--space-stack-2xl);
		width: 100;
	}
}

.section {
	max-width: 1500px;

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 1270px;
	}

	@media (min-width: variables.$breakpoint-max) {
		max-width: 1680px;
	}
}
