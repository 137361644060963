@use 'src/styles/animations' as animations;
@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex-direction: column;
	animation: fadeIn 1s;
	max-width: 44rem;
	overflow: auto;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: var(--space-stack-md);
		padding-bottom: var(--space-stack-xs);
		height: var(--space-inset-3xl);
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: var(--space-stack-xs);
		height: 4.375rem;

		.logoContainer {
			display: flex;
			align-items: center;

			.logo {
				max-width: 4.375rem;
				max-height: 4.375rem;
			}
		}
	}
}
