@use 'src/styles/variables' as variables;

.container {
	@media (max-width: variables.$breakpoint-lg) {
		padding: 0 var(--space-inset-xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-xl);
	}
}

.textContainer {
	padding: var(--space-inset-5xl) var(--space-inset-3xl);

	@media (max-width: variables.$breakpoint-xl) {
		padding: var(--space-inset-xl) 0;
	}

	@media (max-width: variables.$breakpoint-lg) {
		order: 2;
	}
}

.gridItemImage {
	@media (max-width: variables.$breakpoint-lg) {
		order: 1;
	}
}

.image {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 22rem;
	overflow: hidden;

	@media (max-width: variables.$breakpoint-md) {
		min-height: var(--image-min-height-sm);
	}
}
