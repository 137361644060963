.card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.btnContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
}
