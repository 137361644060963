@use 'src/styles/variables' as variables;

.yellowBrandline {
	background-color: var(--primary-yellow);
}

.copy {
	max-width: 60%;
}

.image {
	overflow: hidden;
}

.header {
	margin-top: var(--space-inset-2xl);
}

.link {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: var(--space-stack-2xl);
}

.linkButton.linkButton.linkButton {
	margin-right: 0;
}

.width--50 {
	margin: 0 auto;
	width: 50%;
	@media (max-width: variables.$breakpoint-xl) {
		width: 100%;
	}
}

.width--60 {
	margin: 0 auto;
	width: 60%;
	@media (max-width: variables.$breakpoint-xl) {
		width: 100%;
	}
}

.width--100 {
	width: 100%;
}
