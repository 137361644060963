@use 'src/styles/variables' as variables;

.container.container.container {
	padding: var(--space-stack-xl);
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;

	&:not(.menuVersionV3) {
		min-height: calc(100vh - var(--global-menu-height));
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl);

		.quoteGrid {
			min-height: calc(100vh - var(--global-menu-height) - (calc(var(--space-inset-3xl) * 2)));
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		padding: var(--space-inset-5xl);
		.quoteGrid {
			min-height: calc(100vh - var(--global-menu-height) - (calc(var(--space-inset-5xl) * 2)));
		}
	}

	&.hasSubMenuV1 {
		.quoteContainer {
			.bigQuote {
				font-size: 3.5rem;
			}
		}
	}

	.section {
		display: flex;
		flex-direction: column;

		@media (max-width: variables.$breakpoint-sm) {
			justify-content: center;
		}
	}
}

.quoteGrid {
	display: flex;
	column-gap: 24rem;
	row-gap: 6rem;
	text-align: center;
	@media only screen and (min-width: 768px) and (max-width: 1023px) {
		column-gap: 8rem;
	}
	@media (max-width: variables.$breakpoint-md) {
		column-gap: 2rem;
	}
}

.headerMobile {
	display: none;
	top: var(--space-stack-xl);
	left: 0;
	width: 100%;

	@media (max-width: variables.$breakpoint-sm) {
		display: block;
	}
}

.logoHeader {
	position: relative;
}

.gridItemContainer {
	align-self: center;
	margin-right: var(--space-inset-5xl);
	min-width: 100%;

	@media (max-width: variables.$breakpoint-sm) {
		margin-right: 0;
	}
}

.logoContainer {
	min-width: 100%;

	@media (max-width: variables.$breakpoint-sm) {
		margin-top: var(--space-stack-3xl);
		min-width: 0;
	}
	.desktopHeader {
		display: none;

		@media (min-width: variables.$breakpoint-sm) {
			display: block;
		}
	}
}

.logoGridItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;

	@media (max-width: variables.$breakpoint-xl) {
		padding: 0;
	}

	@media (max-width: variables.$breakpoint-sm) {
		padding: 2rem;
	}
}

.logoGridItem:nth-child(odd) {
	justify-self: end;
}

.image {
	position: relative;
	width: 20rem;

	img {
		max-width: 250px !important;
		max-height: 120px !important;
	}

	@media (min-width: variables.$breakpoint-sm) and (max-width: variables.$breakpoint-max) {
		width: 25rem;
		img {
			max-width: 250px !important;
			max-height: 90px !important;
		}
	}

	@media (min-width: variables.$breakpoint-max) {
		width: 30rem;
		img {
			max-width: 400px !important;
			max-height: 160px !important;
		}
	}
}

.cardContainer {
	justify-content: flex-end;
}

.testimonialTextContainer {
	display: flex;
	flex-direction: column;
	.bigQuote {
		margin-bottom: var(--space-stack-xl);
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		.testimonialTextContainer {
			width: 100%;
		}
	}
}
