@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.container {
	display: flex;
}

.line,
.line1,
.line2,
.line3 {
	border-radius: 1rem;
	height: functions.rem(8px);
}

.line {
	background-color: var(--brand-stripe-1-bg);
	width: 7.8125rem;
}

.margin-bottom--md {
	margin-bottom: var(--space-stack-md);
}

.margin-bottom--xl {
	margin-bottom: var(--space-stack-xl);
}

.line1 {
	margin-right: 0.5rem;
	background-color: var(--brand-stripe-1-bg);
	width: functions.rem(206px);
	@media (max-width: 48rem) {
		width: functions.rem(131.54px);
	}
}

.line2,
.line3 {
	margin-right: 0.5rem;
}

.line1,
.line2,
.line3 {
	margin-bottom: 3rem;
}

.line2 {
	background-color: var(--brand-stripe-2-bg);
	width: functions.rem(144.65px);
	@media (max-width: 48rem) {
		width: functions.rem(65.77px);
	}
}

.line3 {
	background-color: var(--brand-stripe-3-bg);
	width: functions.rem(211.16px);
	@media (max-width: 32rem) {
		width: functions.rem(98.66px);
	}
}
