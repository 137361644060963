.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.inner {
	max-width: var(--text-max-width);
}

.smallTitleContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.buttonsContainer {
	margin-bottom: var(--space-inset-3xl);
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: var(--space-inset-lg);
}

.link.link.link {
	margin-right: 0;
}
