@use 'src/styles/variables' as variables;

.container {
	color: var(--text-dark);
}

.title {
	color: var(--text-heading-color);
}

@media (max-width: variables.$breakpoint-xl) {
	.grid {
		column-gap: 2rem;
	}
}
