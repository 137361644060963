@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.brandLine {
	--brand-stripe-1-bg: var(--primary-yellow);
}

.calendarList {
	margin: 0;
	padding: 0;
	list-style-type: none;

	.calendarListItem {
		--body-color: var(--primary-yellow);
		--text-primary-color: var(--text-heading-color);
		--text-secondary-color: var(--text-heading-color);
		display: flex;
		align-content: center;
		margin-bottom: var(--space-stack-md);
		height: 100%;

		.date {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
			background: var(--body-color);
			padding: 10px;
			min-width: functions.rem(83px);

			span {
				color: var(--text-secondary-color);
				line-height: functions.rem(24px);
			}

			.bigDateText {
				color: var(--text-primary-color);
				font-weight: 600;
				font-size: functions.rem(40px);
				line-height: functions.rem(40px);
			}
			.bigMonthText {
				color: var(--text-primary-color);
				font-weight: 600;
				font-size: functions.rem(30px);
				line-height: functions.rem(40px);
			}
		}

		.grid {
			border: 2px solid var(--body-color);
			border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
			padding: var(--space-inset-xl);
			min-height: functions.rem(110px);

			.gridItem {
				display: flex;
				align-items: center;
			}

			.description {
				color: var(--text-primary-color);
			}

			.linkContainer {
				a {
					margin: 0;
				}

				@media (min-width: variables.$breakpoint-sm) {
					justify-content: flex-end;
				}
			}
		}
	}
}

.button.button.button {
	padding-top: var(--space-inset-md);
}
