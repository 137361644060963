.container {
	position: relative;
}

.backgroundOne {
	position: absolute;
	width: 50%;
	height: 100%;
}

.backgroundTwo {
	position: absolute;
	right: 0;
	width: 50%;
	height: 100%;
}
