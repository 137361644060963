@use 'src/styles/variables' as variables;

.searchField {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: var(--space-inline-xs);
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-md);
	padding-right: var(--space-inline-md);
	width: 100%;
	height: 3rem;
}

.inputField {
	margin-left: var(--space-inline-md);
	outline: none;
	border: none;
	width: 100%;
	min-width: 11.5rem;
}
.clearIcon {
	cursor: pointer;
	width: 1.25rem;
	height: 1.25rem;
	color: var(--text-primary);
}
