@use 'src/styles/variables' as variables;

.container {
	--brand-stripe-1-bg: var(--text-yellow);
	color: var(--body-color);

	.content {
		align-self: center;
	}

	@media (min-width: 75rem) {
		min-height: 50vh;
	}

	&.layout--left {
		div:nth-of-type(1) {
			order: 1;
		}

		@media (min-width: 48rem) {
			.content {
				padding: var(--space-stack-3xl);
			}
		}
	}

	&.layout--right {
		div:nth-of-type(1) {
			@media (max-width: 63rem) {
				order: 1;
			}
		}
	}

	&.layout--without-image {
		margin-bottom: 0;
		.content {
			@media (min-width: 75rem) {
				margin: 0 auto;
				width: 1024px;
			}
		}
	}

	&.hasSubMenuV1 {
		.content {
			padding-right: 0;
			padding-left: 0;
			max-width: 74.5rem;

			@media (max-width: variables.$breakpoint-max) {
				max-width: 55rem;
			}

			@media (max-width: 1600px) {
				max-width: 62rem;
			}

			@media (max-width: variables.$breakpoint-xl) {
				max-width: 100%;
			}
		}
	}
}

.smallTitle {
	--title-color: var(--body-color);
}

.text {
	padding-top: var(--space-stack-xl);
}

.imageContainer {
	position: relative;
}

.image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.linkButton {
	padding-top: var(--space-stack-xl);
}

.theme--yellow {
	--body-color: var(--text-dark);
	--icon-color: var(--text-dark);
}

.theme--green {
	--body-color: var(--text-light);
	--icon-color: var(--text-yellow);
	--title-color: var(--text-light);
}

.theme--dark-green {
	--body-color: var(--text-light);
	--title-color: var(--text-light);
	--icon-color: var(--text-yellow);
}

.theme--purple {
	--body-color: var(--text-light);
	--title-color: var(--text-light);
	--icon-color: var(--text-yellow);
}

.theme--beige-25 {
	--body-color: var(--text-dark);
}

.theme--beige-10 {
	--body-color: var(--text-dark);
}

.theme--white {
	--body-color: var(--text-dark);
	.smallTitle {
		--title-color: var(--primary-green);
	}
}

.theme--light-green {
	--body-color: var(--text-dark);
	--icon-color: var(--text-dark);
	.smallTitle {
		--title-color: var(--primary-green);
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		.image {
			position: relative;
			min-height: 15rem;
		}
	}
}
