@use 'src/styles/variables' as variables;

.image {
	position: relative;
	margin-bottom: var(--space-stack-3xl);
	border-radius: var(--border-radius-4xl);
	height: 32rem;
	overflow: hidden;

	@media (max-width: variables.$breakpoint-md) {
		height: 18rem;
	}
}

.textWrapper {
	margin-bottom: var(--space-stack-3xl);

	h2 {
		margin-top: var(--space-stack-3xl);
	}

	h3 {
		margin-top: var(--space-stack-2xl);
	}

	h4 {
		margin-top: var(--space-stack-xl);
	}
}

.socialMediaFooter {
	display: flex;
	justify-content: flex-start;
	gap: var(--space-inset-md);

	@media (max-width: variables.$breakpoint-md) {
		justify-content: space-between;
	}
}
.brandline {
	@media (max-width: variables.$breakpoint-lg) {
		justify-self: flex-end;
	}
}

.tagContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: var(--space-stack-xl);
	margin-bottom: var(--space-stack-xl);
	padding-bottom: var(--space-stack-md);

	.tags {
		margin-bottom: 0;
		@media (max-width: variables.$breakpoint-md) {
			margin-bottom: var(--space-stack-md);
		}
	}

	@media (max-width: variables.$breakpoint-lg) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		.tags {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
}

.infoContainer {
	display: flex;
	justify-content: space-between;

	.published {
		text-align: right;
	}

	.buttonContainer {
		display: flex;
		gap: var(--space-inset-sm);
		cursor: pointer;
	}
}

.modalList {
	margin: 0;
	margin-bottom: var(--space-stack-xl);
	padding: 0;
	list-style-type: none;

	.listItem {
		margin-bottom: var(--space-stack-sm);
	}
}

.section {
	margin: 0 auto;
	width: fit-content;
}

.grid aside {
	display: block;
}
.grid {
	display: grid;
	grid-template-columns: auto 52rem 18rem;
	gap: var(--space-inset-3xl);
	margin: 0 auto;

	@media (min-width: variables.$breakpoint-lg) {
		grid-template-columns: 18rem minmax(auto, 52rem);
		.fill {
			display: none;
		}
	}

	@media (max-width: variables.$breakpoint-lg) {
		display: block;
		aside {
			display: none;
		}
	}

	.sidepanelContent {
		position: sticky;
		top: 8rem;
		transition: top 0.3s ease-in-out;
		border-right: 1px solid var(--line-color);
		padding: 0 var(--space-inset-xl);

		&.scrollUp {
			top: 12rem;
		}
	}

	.list {
		margin: 0;
		padding: 0;
		list-style-type: none;

		.listItem {
			margin-bottom: var(--space-stack-xs);
		}
	}

	.tableOfContents {
		margin-bottom: var(--space-stack-xl);
	}
}

.modal {
	width: 50rem;
	max-width: 100%;
}
