@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: fixed;
	flex-direction: column;
	z-index: var(--z-index-10);
	transition:
		height 0.4s ease-in-out,
		background-color 0.4s ease-in-out;
	margin: 0 auto;
	inset: var(--global-menu-offset);
	border-radius: var(--border-radius-menu);
	width: calc(100vw - var(--global-menu-offset) * 2 - var(--scrollbar-width));
	max-width: calc(var(--global-max-width) - var(--global-menu-offset) * 2);
	height: var(--global-menu-height);
	&.isMenuOpen {
		height: calc(100svh - (var(--global-menu-offset) * 2));

		.loginButtonMobile {
			@media (max-width: variables.$menu-bp) {
				display: block;
			}
		}
	}
}

.opaqueBgColor {
	background-color: var(--bg-dark-green-full);
}

.transparentBgColor {
	backdrop-filter: blur(2px);
	background-color: var(--bg-dark-green-full-90);
}

.hamburgerMenu {
	padding: var(--space-inset-md) var(--space-inset-md) var(--space-inset-md) var(--space-inset-xl);
	max-height: var(--global-menu-height);
	@media (max-width: variables.$menu-bp) {
		padding: var(--space-inset-sm) var(--space-inset-lg);
	}
}

.inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media (max-width: variables.$menu-bp) {
		height: 2.0265rem;
	}
}

.leftNav,
.rightNav {
	display: inline-flex;
	align-items: center;
}

.leftNavLinks {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;

	@media (max-width: variables.$menu-bp) {
		display: none;
	}
}

.logoWrapper {
	position: relative;
	margin-right: var(--space-inline-3xl);
}

.logo {
	display: flex;
	align-items: center;
}

.rightNav {
	justify-content: flex-end;
	gap: 1.5rem;

	@media (max-width: variables.$menu-bp) {
		gap: 0;
	}
}

.searchToggleButton {
	width: 1.5rem;
	height: 1.5rem;
}

.loginButton,
.contactLinkButton {
	@media (max-width: variables.$menu-bp) {
		display: none;
	}
}

.infoBannerBtn.infoBannerBtn.infoBannerBtn {
	margin-right: var(--space-inline-md);
	border-radius: var(--border-radius-2xl);
	padding: var(--space-inset-xs);
	min-width: 0;
	max-height: 2.3rem;
}

.menuContentDesktop,
.menuContentDesktop > * {
	@media (max-width: variables.$menu-bp) {
		display: none;
		height: 0 !important;
	}
}

.menuContentMobile.menuContentMobile.menuContentMobile {
	display: none;

	@media (max-width: variables.$menu-bp) {
		display: flex;
	}
}

.footer {
	display: flex;
	position: relative;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.overlay {
	position: absolute;
	top: -3rem;
	right: 0;
	left: 0;
	z-index: var(--z-index-max);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.25) 0%,
		rgba(0, 0, 0, 0.15) 25%,
		rgba(0, 0, 0, 0.05) 50%,
		rgba(0, 0, 0, 0) 75%
	);
	height: 3rem;
}

.loginButtonMobile {
	display: none;
}
