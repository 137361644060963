@use 'src/styles/variables' as variables;

.container {
	background-color: var(--bg-light-grey);
}

.addSpace {
	padding: var(--space-stack-md) 0;
	width: 100%;
	height: 100%;
}

@media (max-width: variables.$breakpoint-xl) {
	.cardMaxWidth {
		max-width: 18.8rem;
	}
}

@media (min-width: variables.$breakpoint-md) {
	.cardMaxWidth {
		max-width: 21.5rem;
	}
}
