@use 'src/styles/functions' as functions;

.container {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: flex-start;
	transition:
		all 0.15s ease-in-out,
		border 0.3s ease-in-out;
	border: functions.rem(1px) solid var(--bg-white);
	border-radius: var(--border-radius);
	background-color: var(--bg-white);
	padding-left: var(--space-inset-md);
	width: 100%;
	height: 3.5rem;

	&.searchField {
		.label {
			margin-left: var(--space-inline-xl);
		}

		.input {
			padding-top: 0.75rem;
			padding-left: var(--space-inline-xl);
		}

		&.compact {
			.input {
				padding-top: 0.65rem;
			}
		}
	}

	&.compact {
		height: 2.5rem;
	}
}

.container.hidden {
	margin: 0;
	border: none;
	height: 0;
}

.input {
	box-sizing: border-box;
	margin: 0;
	outline: none;
	box-shadow: none;
	border: none;
	background: transparent;
	padding: 1rem 0 0;
	width: 100%;
	line-height: 2;
	text-overflow: ellipsis;

	&::placeholder {
		color: var(--custom-text-placeholder);
	}

	&[type='search']::-webkit-search-decoration,
	&[type='search']::-webkit-search-cancel-button,
	&[type='search']::-webkit-search-results-button,
	&[type='search']::-webkit-search-results-decoration {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
	}
}

.input.active,
.textarea.active {
	border: none;
}

.input:disabled {
	cursor: not-allowed;
	color: var(--disabled-color);
}

.bordered {
	border: functions.rem(1px) solid var(--border-color);
}

.textarea {
	outline: none;
	border: none;
	border-radius: var(--border-radius-md);
	padding: 1.25rem 0 0;
	width: 100%;
}

.label {
	position: absolute;
	left: var(--space-inset-md);
	transform: translateY(90%);
	transform-origin: 0 100%;
	transition: all 0.15s ease-in-out;
	pointer-events: none;
	color: var(--read-only);
	line-height: normal;
}

.label.active,
.label.not-empty {
	position: absolute;
	top: 6px;
	left: var(--space-inset-md);
	transform: scale(0.8);
	transform-origin: 0 0;
	background-color: var(--bg-white);
	width: 100%;
}

.no-label {
	transform: translateY(50%);
}

.iconContainer {
	position: absolute;
	top: 0.8rem;

	.icon {
		width: functions.rem(20px);
	}
}

.validationError {
	border: functions.rem(1px) solid var(--danger-color);
}

.validationMessage {
	bottom: -10%;
	transform: translateY(0);
	transition: 0.2s;
	height: 1rem;
	color: var(--danger-color);
	font-size: 0.75rem;

	&.compact {
		height: 0.6rem;
		font-size: 0.6rem;
	}
}

/* Change Autocomplete styles in Chrome*/
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.textarea:-webkit-autofill,
.textarea:-webkit-autofill:hover,
.textarea:-webkit-autofill:focus {
	-webkit-text-fill-color: var(--body-color);
	transition: background-color 5000s ease-in-out 0s;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset;
	border: none;
}

/* COMPACT VARIATIONS */

.compact {
	.label {
		font-size: 0.9rem;
		line-height: 1;
	}
	.label.active,
	.label.not-empty {
		top: 3px;
		transform: scale(0.7);
	}

	.input {
		font-size: 0.9rem;
		line-height: 0;
	}

	.iconContainer {
		top: 0.3rem;
	}
}

.tooltipWrapper {
	position: absolute;
	top: 4px;
	right: 4px;
}
