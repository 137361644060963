.card {
	background-color: var(--bg-beige-light);
}

.bgColor {
	background-color: var(--bg-beige-light);
}

.titleColor {
	--title-color: var(--text-heading-color);
	color: var(--title-color);
}

.smallTitleColor {
	color: var(--primary-green);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}

.accentTextColor {
	color: var(--text-pink-full);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--primary-yellow);
}

.backgroundPattern {
	background: url('/patterns/fortnox-pattern-beige.svg');
	background-position: center;
	background-size: cover;
	background-color: var(--bg-beige-light) !important;
}

.uspIconColor {
	color: var(--icon-color);
	--icon-color: var(--primary-green);
}

.iconColor,
.antiIconColor {
	color: var(--icon-color);
	--icon-color: var(--text-dark);
}

.borderColor {
	--themed-border-color: var(--bg-dark-green-full);
	border-color: var(--themed-border-color);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--primary-green);
	color: var(--text-light);

	&:hover {
		background: var(--bg-dark-green-full);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--primary-green);
	background: transparent;
	color: var(--primary-green);

	&:hover {
		border-color: var(--primary-dark-green) !important;
	}
}

.textLink {
	--link-hover-effect-color: var(--primary-green);
	color: var(--primary-green);

	&:hover {
		color: var(--primary-green);
	}
}

// LinkToggle
.linkToggleActive {
	background-color: var(--bg-white);
	color: var(--text-primary-green);
}

.linkToggleBgColor {
	background-color: var(--bg-green-full);
}

// Carousel indicators
.carouselIndicatorColor {
	--indicator-color: var(--action-color);
}
