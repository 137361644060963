@use 'src/styles/variables' as variables;

.container {
	background-color: var(--bg-dark-green-extra-light);

	.newsContainer {
		min-height: 10rem;
	}

	.news {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.title {
		color: var(--primary-dark-green);
	}
	.newsTitle,
	.newsContent {
		padding-left: var(--space-inset-sm);
	}

	.row {
		display: flex;
		max-width: 50rem;
	}

	.newsTitle {
		color: var(--primary-dark-green);
		font-weight: 600;
	}

	.checkIcon {
		padding-bottom: var(--space-stack-3xs);
	}

	.stickerGrid {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: var(--space-inset-5xl);
		@media (max-width: variables.$breakpoint-md) {
			margin-top: var(--space-inset-6xl);
			margin-bottom: var(--space-stack-xl);
		}
	}

	.sticker {
		position: relative;
	}
}
