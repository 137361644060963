@use 'src/styles/variables' as variables;

.card {
	width: 21.5rem;

	@media (max-width: variables.$breakpoint-sm) {
		width: 16.5rem;
	}
}

.fourCards {
	@media (max-width: variables.$breakpoint-xl) and (min-width: #{variables.$breakpoint-lg + 101px}) {
		width: 16.5rem;
	}
}

.threeCards {
	@media (max-width: variables.$breakpoint-xl) and (min-width: #{variables.$breakpoint-lg + 1px}) {
		width: 16.5rem;
	}
}

.oneCard {
	@media (max-width: variables.$breakpoint-sm) {
		width: 100%;
		max-width: 21.5rem;
	}
}

.carousel.fourCardsCarousel {
	@media (max-width: #{variables.$breakpoint-lg + 100px}) {
		display: block;
	}
}

.carousel.threeCardsCarousel {
	@media (max-width: variables.$breakpoint-lg) {
		display: block;
	}
}

.container.threeCardsContainer {
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.container.fourCardsContainer {
	@media (max-width: #{variables.$breakpoint-lg + 100px}) {
		display: none;
	}
}

.container.oneCardContainer {
	display: flex;
}

.container {
	display: flex;
	justify-content: center;

	.card {
		margin: 0 var(--space-inline-md);
	}

	@media (max-width: variables.$breakpoint-xl) and (min-width: variables.$breakpoint-lg) {
		.fourCards + .card {
			margin: 0 var(--space-inline-xs);
		}
	}

	@media (max-width: variables.$breakpoint-md) {
		display: none;
	}
}

.additionalText {
	margin-top: var(--space-stack-3xl);
	text-align: center;
	&.adjustMargin {
		margin-bottom: 0;
	}
}

.carousel {
	display: none;
	max-width: 100vw;

	.card {
		align-items: center;
		margin-right: var(--space-inline-md);
		margin-left: var(--space-inline-md);
		padding-top: var(--space-inline-lg);
		padding-bottom: var(--space-inline-3xl);
		height: 100%;
	}

	@media (max-width: variables.$breakpoint-md) {
		display: block;
	}

	> div,
	> div > div {
		overflow: inherit !important;
		overflow-x: clip !important;
	}
}

.linkButtonContainer {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-3xl);
	&.adjustMargin {
		margin-bottom: 0;
	}
}
