.button {
	border: none;
	background: none;
	padding: 0;
}

.geolocationButton {
	.img,
	svg {
		vertical-align: middle;
	}
}

.geolocationErrorButton {
	.img,
	svg {
		vertical-align: middle;
		color: var(--danger-color);
	}
}

.geolocationSlashButton {
	.img,
	svg {
		vertical-align: middle;
	}
}
