@use 'src/styles/variables' as variables;

.sectionHeader {
	padding-bottom: 0 !important;
}

.text {
	margin-bottom: var(--space-inset-xl);
}

.imageText {
	margin-top: 1rem;
}

.priceContainer {
	display: flex;
	align-items: flex-end;
}

.signUpButtonCol {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-end;
}

.bookingBtn {
	align-items: flex-end;
	float: right;
}

.recordedCoursePriceBox {
	margin-bottom: var(--space-stack-md);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-md);
}

.containerInsideTd {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	.onDemand {
		font-weight: 600;
	}

	div,
	p {
		margin: 0;
	}
}

.priceCol {
	display: flex;
}

.priceHeader {
	margin-right: 0.5rem;
}

.priceRowWithCourseName {
	align-items: center;
}

.priceRow.priceRow.priceRow {
	background-color: var(--bg-beige-light);

	tr {
		border: none !important;
	}

	.containerInsideTd {
		border: none;
	}

	td::before {
		content: '';
	}
}
.spaceRow.spaceRow.spaceRow {
	display: none;
}

@media (min-width: variables.$breakpoint-lg) {
	.educationTableContainer {
		table,
		tbody {
			max-width: 100%;

			tr {
				td {
					min-width: 5rem;
				}
				th {
					min-width: auto;
				}
			}
		}
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.educationTableContainer {
		table,
		tbody {
			tr {
				width: 100%;
				td {
					width: 100%;
				}
				th {
					min-width: 100%;
				}
			}
		}
	}
}
