@use 'src/styles/variables' as variables;

// Common
.card {
	display: flex;
	flex-direction: column;
	z-index: var(--z-index-1);
	a {
		margin-right: 0;
	}

	&.padding {
		padding: var(--space-inset-3xl) var(--space-inset-xl);

		@media (max-width: variables.$breakpoint-xl) {
			padding: var(--space-inset-xl);
		}
	}

	&.opacity {
		opacity: 0.9;
	}
}

.btn.btn.btn {
	margin-right: 0;
}

.btnWrapper {
	display: flex;
	flex: 1;
	align-items: flex-end;
}

.cardSmall {
	.btnCenter {
		justify-content: center;
	}
}

.secondBtn {
	margin-left: var(--space-inline-xl);
}

// CardSmallIcon

.headerIconWrapper {
	display: flex;
	align-items: center;
	margin-bottom: var(--space-stack-md);

	.icon {
		width: 2rem;
		height: 2rem;
		font-size: 2rem;
	}

	.iconWrapper {
		margin-right: var(--space-inline-md);
	}
}

.layout--center {
	text-align: center;
	.headerIconWrapper {
		flex-direction: column;
		margin-bottom: var(--space-stack-xl);
	}

	.noText {
		margin-bottom: var(--space-stack-3xl);
	}

	.icon {
		width: 8rem;
		height: 8rem;
		font-size: 8rem;
	}

	.customIcon {
		width: 10rem;
		height: 10rem;
	}

	.iconWrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-right: 0;
		margin-bottom: var(--space-stack-xl);
		width: 100%;
	}

	.btnWrapper {
		justify-content: center;
	}
}

.cardLink {
	cursor: pointer;
}
