@use 'src/styles/variables' as variables;

.linkToggle {
	border-radius: 1.9rem;
	padding: 0.5rem;
}

.disableHoverEffect {
	pointer-events: none;
}

.inactiveLink.inactiveLink {
	background-color: transparent;
	font-weight: 400;

	@media (max-width: variables.$breakpoint-lg) {
		border: 1px solid rgba(0, 0, 0, 0.15);
	}
	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}
}
