.usp {
	display: flex;
	align-items: center;
}

.icon {
	transform: translateY(0.125rem);
	margin-right: var(--space-inline-md);
}

.muted {
	opacity: 0.5;
}
