@use 'src/styles/variables' as variables;

.priceItem {
	align-items: center;

	padding: var(--space-inline-md) 0;
	height: auto;

	@media (max-width: variables.$breakpoint-sm) {
		margin-bottom: var(--space-stack-lg);
		border: 1px solid var(--border-color);
		border-radius: var(--border-radius-md);
		padding: var(--space-inline-md);
	}

	@media (min-width: variables.$breakpoint-md) {
		&.noBorder {
			border: none;
		}
	}
}

.mobileInfo {
	display: none;
	@media (max-width: variables.$breakpoint-md) {
		display: block;
		margin-top: var(--space-stack-md);
	}
}

.desktopInfo {
	display: none;
	border-bottom: 1px solid var(--border-color);
	@media (min-width: variables.$breakpoint-md) {
		display: block;
	}
}

.productItem {
	display: flex;
}

.priceText,
.productName {
	align-self: center;
}

.removeMarginBottom {
	margin-bottom: 0;
}

.productName {
	margin-bottom: 0;

	@media (max-width: variables.$breakpoint-sm) {
		margin-bottom: var(--space-stack-md);
	}
}

.tooltipWrapper {
	flex-grow: 1;
	margin-top: -0.25rem;
}

.tooltip.tooltip.tooltip {
	max-width: 34rem;

	@media (max-width: variables.$breakpoint-md) {
		width: auto;
		max-width: initial;
	}
}

.gridItemBtn {
	display: flex;
	justify-content: flex-end;

	@media (max-width: variables.$breakpoint-sm) {
		margin-top: 1.5rem;
	}
}

.btn.btn.btn {
	margin-right: 0;
}

.priceText {
	margin-bottom: 0;

	@media (max-width: variables.$breakpoint-sm) {
		border-bottom: var(--border-color-light) 1px solid;
		padding: var(--space-inline-sm) 0;
		width: 100%;
	}
}

.subTitle {
	display: none;

	@media (max-width: variables.$breakpoint-sm) {
		display: inline-block;
		max-width: 70%;
	}
}

.price {
	@media (max-width: variables.$breakpoint-sm) {
		float: right;
	}
}

.noMonthlyPrice {
	.productName {
		@media (max-width: variables.$breakpoint-sm) {
			margin-bottom: 0;
		}
	}

	.priceText {
		@media (max-width: variables.$breakpoint-sm) {
			border-bottom: none;
			padding: 0;
		}
	}
}
