.hidden {
	display: none;
}

.theme--dark-green {
	background-color: var(--bg-dark-green-full);
}

.theme--green {
	background-color: var(--primary-green);
}

.theme--white {
	background-color: var(--bg-white);
}

.theme--light-green {
	background-color: var(--bg-dark-green-extra-light);
}
