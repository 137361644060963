@use 'src/styles/variables' as variables;

.center {
	margin: 0 auto;
	max-width: 67rem;
}

.smallGap {
	column-gap: var(--space-inset-3xl);
	row-gap: var(--space-inset-3xl);

	@media (max-width: variables.$breakpoint-xl) {
		column-gap: var(--space-inset-xl);
		row-gap: var(--space-inset-xl);
	}
}

.gap {
	column-gap: var(--space-inset-3xl);
	row-gap: var(--space-inset-3xl);
}

.nr-of-grid-items--5 {
	.gridItem {
		grid-column: span 1;

		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 2;

			&:nth-child(4) {
				grid-column: 2 / span 2;
			}
		}
	}
}

.groupedGrid {
	&.oddNrOfCards {
		.gridItem {
			grid-column: span 2;

			&:last-child:nth-child(odd) {
				grid-column: 2 / span 2;
			}
		}
	}
}

.unGroupedGrid {
	&.oddNrOfCards {
		.gridItem {
			@media (max-width: variables.$breakpoint-md) {
				grid-column: span 4;
			}

			@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
				grid-column: span 2;
				&:last-child:nth-child(odd) {
					grid-column: 2 / span 2;
				}
			}
		}
	}
}

.showCarousel {
	.grid {
		@media (max-width: variables.$breakpoint-md) {
			display: none;
		}
	}

	.carousel {
		display: none;

		> div {
			padding-bottom: 4rem;
		}

		.card {
			height: 100%;
			text-align: left;
		}

		li:first-child {
			.card {
				margin-left: var(--space-inline-xl);
			}
		}

		li {
			.card {
				margin-right: var(--space-inline-xl);
			}
		}

		@media (max-width: variables.$breakpoint-md) {
			display: block;
		}
	}
}

.grid {
	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-xl);
	}
}

.btnPosition {
	display: flex;
	justify-content: center;
	margin-top: 8rem;

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: 5rem;
	}
}
