@use 'src/styles/variables' as variables;

.brandLine {
	--brand-stripe-1-bg: var(--primary-yellow);
	height: 0.25rem;
}

.subMenuTextContent {
	width: 100%;
	max-width: 74.5rem;

	@media (max-width: 1800px) {
		max-width: 62rem;
	}

	@media (max-width: 1600px) {
		max-width: 55rem;
	}

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 100%;
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.textContent {
		width: 60%;
	}
}

@media (min-width: variables.$breakpoint-xl) and (max-width: variables.$breakpoint-xl) {
	.textContent {
		width: 65%;
	}
}
