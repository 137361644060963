@use 'src/styles/variables' as variables;

.video {
	position: relative;
	padding-top: 56.25%; /* 16:9 Aspect Ratio */
	width: 100%;
	overflow: hidden;
}

.videoFooter {
	margin-top: var(--space-stack-md);
}

.responsive {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
}

.width--50 {
	margin: 0 auto;
	width: 50%;
	@media (max-width: variables.$breakpoint-xl) {
		width: 100%;
	}
}

.width--60 {
	margin: 0 auto;
	width: 60%;
	@media (max-width: variables.$breakpoint-xl) {
		width: 100%;
	}
}

.width--100 {
	width: 100%;
}

.caption {
	margin-top: 0.5rem;
}
