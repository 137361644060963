@use 'src/styles/variables' as variables;
@use 'src/styles/functions' as functions;

.form {
	width: 100%;
}

.errorMsgContainer {
	margin-top: var(--space-stack-xl);
	color: var(--text-dark);

	.btnWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.btnContainer {
	button {
		float: right;
	}
}

.textFieldContainer > div {
	height: 0;
}

.inputFirstName {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	width: 0;
	height: 0;
}

.feedbackTitle {
	margin-top: functions.rem(4px);
	margin-left: var(--space-stack-md);
}

.iconContainer {
	display: flex;
	align-items: center;
	padding-bottom: var(--space-stack-md);
	width: 100%;
}

.centerFeedbackText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	text-align: center;

	.iconContainer {
		justify-content: center;
	}
}

.refreshBtn {
	margin-top: var(--space-stack-md);
}

.integrityMsgBtn {
	padding-right: 0;
	font-size: 0.875rem;
}

.consentText {
	word-break: break-word;
}

.endOfFormContent {
	.gridItem:nth-of-type(1) {
		// consent
		order: 3;
	}
	.gridItem:nth-of-type(2) {
		// captcha
		order: 4;
	}
	.gridItem:nth-of-type(3) {
		// phone
		order: 1;
	}
	.gridItem:nth-of-type(4) {
		// btn
		order: 2;
	}
}

.inCard {
	.gridItem {
		text-align: right;
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.endOfFormContent {
		.gridItem:nth-of-type(1) {
			// consent
			order: 1;
		}
		.gridItem:nth-of-type(2) {
			// captcha
			order: 2;
		}
		.gridItem:nth-of-type(3) {
			// phone
			order: 3;
		}
		.gridItem:nth-of-type(4) {
			// btn
			order: 4;
		}
	}

	.consentText {
		text-align: left;
	}

	.phoneContainer {
		display: flex;
		align-items: center;
		height: 100%;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.endOfFormContent {
		.gridItem:nth-of-type(1) {
			// consent
			order: 3;
		}
		.gridItem:nth-of-type(2) {
			// captcha
			order: 4;
		}
		.gridItem:nth-of-type(3) {
			// phone
			order: 2;
		}
		.gridItem:nth-of-type(4) {
			// btn
			order: 1;
		}
	}

	.phoneContainer {
		justify-content: flex-end;
		width: 100%;
		text-align: right;
	}

	.consentText {
		width: 100%;
		text-align: right;

		a {
			margin-right: 0 !important;
		}

		span {
			text-align: right;
		}
	}
}

/* COMPACT VARIATION */

.compact {
	.consentText {
		font-size: 0.7rem;
		line-height: 1.1;

		a {
			font-size: 0.7rem;
		}
	}
}
