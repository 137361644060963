.videoColumn {
	margin: 0 auto;
	width: 100%;
	max-width: 60rem;
}

.textColumn {
	align-self: center;
}

.section {
	height: 100vh;
}
.gridItemVideo {
	display: flex;
	align-items: center;
}
