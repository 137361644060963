@use 'src/styles/animations' as animations;
@use 'src/styles/variables' as variables;

.link {
	display: block;
}
.link a,
.button {
	cursor: pointer;
	color: var(--text-light);
	font-weight: 500;
	line-height: 1.2;
	text-align: start;
}

.link a:hover {
	color: var(--text-light);
}

.groupLink {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 0.25rem;
	width: 100%;

	.iconWrapper {
		float: right;

		.icon {
			color: var(--text-light);
		}
	}

	button {
		border: none;
		background: none;
		padding: inherit;
	}
}

.linkWrapper {
	display: flex;
	margin-bottom: 0.5rem;

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: 0.5rem;
	}
}

.linkWithSublinkList {
	margin-bottom: 0.25rem;
}

.sublinkList {
	margin: 0;
	margin-bottom: var(--space-stack-md);
	padding: 0;
	padding-left: var(--space-inset-sm);
	list-style-type: none;
}

.effectWrapper {
	display: block;
	position: relative;

	button {
		&::after {
			position: absolute;
			bottom: -0.4rem;
			left: 0;
			transform: scaleY(0);
			transform-origin: bottom left;
			transition: transform 0.3s;
			background-color: var(--primary-yellow);
			width: 100%;
			height: 2px;
			content: '';
		}

		&:focus,
		&:hover,
		&.active {
			outline: none;
			text-decoration: none;
			&::after {
				transform: scaleY(1);
			}
		}
	}
}
