@use 'src/styles/variables' as variables;

.container {
	border-radius: var(--border-radius-md);
	padding: var(--space-inset-3xl) var(--space-inset-2xl);
	height: 32rem;
	overflow: hidden;
}

.published {
	margin-bottom: var(--space-stack-2xl);
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		padding-bottom: var(--space-stack-2xl);
		height: auto;
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.contentWrapper {
		max-width: 70%;
	}
}

@media (max-width: variables.$breakpoint-sm) {
	.container {
		padding: var(--space-stack-xl) var(--space-inset-lg);
	}
}

.noPadding {
	padding: 0;
	height: auto;
}
