.headerContainer {
	display: flex;
}

.icon {
	margin-right: var(--space-inline-xs);
}

.textContainer {
	margin-bottom: var(--space-stack-md);
}
