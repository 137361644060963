@use 'src/styles/variables' as variables;

.hasMobileImage {
	.image {
		@media (max-width: variables.$breakpoint-lg) {
			display: none;
		}
	}

	.mobileImage {
		display: none;

		@media (max-width: variables.$breakpoint-lg) {
			display: block;
		}
	}
}

.layout--50-50 {
	.mobileImage,
	.image {
		@media (min-width: variables.$breakpoint-lg) {
			min-height: 64.125rem;
		}
	}
}

.mobileImage,
.image {
	position: relative;
	width: 100%;
	min-height: 100%;

	@media (max-width: variables.$breakpoint-lg) {
		min-height: 34.125rem;
	}

	@media (max-width: variables.$breakpoint-sm) {
		min-height: var(--image-height);
	}
}

.inner {
	display: flex;
	flex-direction: column;
	padding: var(--space-inset-5xl);
	height: 100%;

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-xl);
	}
}

.cardGrid.cardGrid.cardGrid {
	column-gap: 6.1875rem;
	height: auto;

	@media (max-width: variables.$breakpoint-xl) {
		column-gap: 4rem;
	}
}

.btnWrapper {
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: flex-end;
	gap: 2rem;
	padding-top: var(--space-inset-3xl);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
		align-items: flex-start;
	}
}
