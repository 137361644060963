.carousel {
	:global(.carousel .control-dots) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.slide-indicator {
		display: inline-block;
		cursor: pointer;
		margin: 0 1rem;
		border-radius: 50%;
		background: var(--indicator-color);
		width: 0.5rem;
		height: 0.5rem;
	}

	.slide-indicator.selected {
		opacity: 1;
		width: 1rem;
		height: 1rem;
	}
}
