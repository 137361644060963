@use 'src/styles/variables' as variables;

.header {
	padding: 0 var(--space-inset-xl);
}

.tabHeader {
	@media (max-width: variables.$breakpoint-lg) {
		padding: 0 var(--space-inline-xl);
	}
}

.tabNavBar {
	position: sticky;
	top: var(--global-menu-height);
	z-index: var(--z-index-2);
}

.theme--light-green {
	background-color: var(--bg-dark-green-extra-light);

	padding: 0 var(--space-inset-xl) 0 var(--space-inset-xl);

	@media (max-width: variables.$breakpoint-lg) {
		padding: 0;
	}

	.leftFade {
		left: 0;
		background: linear-gradient(to left, rgba(247, 245, 245, 0), rgba(247, 245, 245, 1));
	}

	.rightFade {
		right: 0;
		background: linear-gradient(to right, rgba(247, 245, 245, 0), rgba(247, 245, 245, 1));
	}
}

.theme--white {
	.leftFade {
		left: 0;
		background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	}

	.rightFade {
		right: 0;
		background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	}
}

.inner {
	padding: var(--space-inset-5xl) 0;

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inset-3xl) 0 0 0;
	}
}

.leftFade,
.rightFade {
	display: none;
	position: absolute;
	top: 0;
	z-index: 99;
	width: 2rem;
	height: 5.8rem;

	@media screen and (max-width: variables.$breakpoint-lg) {
		display: block;
	}
}

.tabNavigation {
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	-ms-overflow-style: none;
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	gap: var(--space-inset-2xl);
	overflow-x: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: variables.$breakpoint-lg) {
		padding: 0 var(--space-inset-xl);
	}
}

.item {
	z-index: 9;
	border: none;
	background: none;
	color: var(--primary-green);
	white-space: nowrap;

	button {
		padding: var(--space-inset-xl) 0 var(--space-inset-xl) 0;
		font-size: 1rem;

		@media (max-width: variables.$breakpoint-xl) {
			font-size: 1.125rem;
		}
	}

	&:hover {
		cursor: pointer;
	}

	.activeIndicator {
		margin-top: -0.5rem;
		border-radius: 0.25rem;
		background-color: var(--primary-yellow);
		height: 0.5rem;

		@media (max-width: variables.$breakpoint-xl) {
			margin-top: -0.3125rem;
			border-radius: 0.15625rem;
			height: 0.3125rem;
		}
	}
}

.navigationBorder {
	transform: translateY(-0.25rem);
	margin: 0;
	background-color: var(--border-color);
	height: 1px;

	@media (max-width: variables.$breakpoint-lg) {
		transform: unset;
	}
}

.tabPanelContainer {
	padding: var(--space-inset-xl) 0 var(--space-inset-xl) 0;
}

.hidden.hidden.hidden {
	opacity: 0;
	padding: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.display {
	opacity: 1;
	transition: opacity 0.3s ease-in;
	width: auto;
	height: auto;
}

.panel {
	border-radius: var(--border-radius-md);
}
