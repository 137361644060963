.theme--dark-green {
	--body-color: var(--text-light);
}

.container {
	margin-bottom: 0;
}

.date {
	font-weight: 700;
}
