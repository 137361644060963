@use 'src/styles/variables' as variables;

.heading {
	text-align: center;
}

.cardGroup {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: stretch;
	gap: var(--space-inset-xl);
	margin: 0 auto;
	max-width: 104rem;

	@media (max-width: variables.$breakpoint-md) {
		margin-right: var(--space-inline-md);
		margin-left: var(--space-inline-md);
	}
}

.cardGroupSmall {
	max-width: 90rem;
}

.button {
	display: flex;
	justify-content: center;
	margin-top: var(--space-inline-3xl);
}

.cardGroupCarousel {
	@media (max-width: variables.$breakpoint-md) {
		display: none;
	}
}

.carousel {
	min-width: 100vw;
	overflow-x: hidden;

	@media (min-width: variables.$breakpoint-md) {
		display: none;
	}

	.card {
		transform: translateY(-4rem);
		margin-top: var(--space-inline-3xl);
		margin-right: var(--space-inline-xs);
		height: calc(100% - 3rem);
	}

	.card.card > div {
		height: 100%;
	}

	li:first-child {
		.card {
			padding-left: var(--space-inline-md);
		}
	}

	li:last-child {
		.card {
			padding-right: var(--space-inline-xs);
		}
	}
}
