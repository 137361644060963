$outlined-border-width: 0.125rem;

.btn {
	display: inline-block;
	border-width: $outlined-border-width;
	border-style: solid;
	border-radius: var(--border-radius-3xl);
	padding: var(--space-inset-2xs) var(--space-inset-md);
	color: var(--action-hovered-color);
	text-align: center;

	&.size--large {
		padding: var(--space-inset-sm) var(--space-inset-lg);
		min-width: var(--space-inset-6xl);
	}
}
