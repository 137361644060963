@use 'src/styles/variables' as variables;

.gridText {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.brandLine {
	--brand-stripe-1-bg: var(--primary-yellow);
	height: 0.25rem;
}

@media (max-width: variables.$breakpoint-lg) {
	.gridText {
		order: 2;
	}
	.gridImage {
		order: 1;
	}
}
