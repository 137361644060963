@use 'src/styles/variables' as variables;

.marginTop.marginTop.marginTop {
	@media (max-width: variables.$breakpoint-xl) {
		margin-top: 6rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		margin-top: 3rem;
	}
}

.tagsAndSocialMedia {
	margin-bottom: var(--space-stack-xl);
	width: 100%;
}

.imageContainer {
	position: relative;
	width: 100%;
}

.image {
	position: relative;
	width: 100%;
	height: 31.25rem;

	@media (max-width: variables.$breakpoint-md) {
		height: 25rem;
	}

	@media (max-width: variables.$breakpoint-sm) {
		height: 12.5rem;
	}
}

.imageFooter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: var(--space-stack-xs);
	width: 100%;
}

.emailLinkContainer a {
	text-decoration: none;
}
