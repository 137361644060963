@use 'src/styles/variables' as variables;

.section {
	$current: var(--padding-base);

	$sizes: (
		'xs': variables.$breakpoint-min,
		'sm': variables.$breakpoint-sm,
		'md': variables.$breakpoint-md,
		'lg': variables.$breakpoint-lg,
		'xl': variables.$breakpoint-xl,
		'xxl': variables.$breakpoint-max,
	);
	margin: 0 auto;
	padding: var(--padding-base);

	@each $size, $minWidth in $sizes {
		$current: var(--padding-#{$size}, $current);

		@media (min-width: $minWidth) {
			padding: $current;
		}
	}

	&.padding-x {
		$currentX: var(--padding-x-base);
		padding-right: var(--padding-x-base);
		padding-left: var(--padding-x-base);

		@each $size, $minWidth in $sizes {
			$currentX: var(--padding-x-#{$size}, $currentX);

			@media (min-width: $minWidth) {
				padding-right: $currentX;
				padding-left: $currentX;
			}
		}
	}

	&.padding-y {
		$currentY: var(--padding-y-base);
		padding-top: var(--padding-y-base);
		padding-bottom: var(--padding-y-base);

		@each $size, $minWidth in $sizes {
			$currentY: var(--padding-y-#{$size}, $currentY);

			@media (min-width: $minWidth) {
				padding-top: $currentY;
				padding-bottom: $currentY;
			}
		}
	}
}
