.jobList {
	display: flex;
	flex-direction: column;
	gap: var(--space-stack-xl) 0;
	margin-bottom: var(--space-stack-3xl);
}

.emptyJobListText {
	text-align: center;
}
