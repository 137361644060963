@use 'src/styles/variables' as variables;

.bgColor {
	background-color: var(--bg-white);
}

.iconColor {
	--icon-color: var(--text-light);
}

.textLink {
	--link-hover-effect-color: transparent;

	&:focus {
		--link-hover-effect-color: var(--text-dark);
	}
}

.textLink,
.iconColor {
	opacity: 0.8;
	color: var(--text-dark);
	--link-hover-effect-color: transparent;

	&:hover {
		opacity: 1;
	}

	@media (pointer: coarse), (max-width: variables.$breakpoint-lg) {
		opacity: 1;
	}
}
