@use 'src/styles/variables' as variables;

.reviewCarousel {
	.reviewItem {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--space-stack-xl);
		border-radius: var(--border-radius-md);
		background-color: var(--bg-dark-green-full);
		padding: var(--space-inline-2xl);
		min-height: var(--space-inset-7xl);

		@media (max-width: variables.$breakpoint-md) {
			flex-direction: column;
			align-items: flex-start;
			min-height: calc(var(--space-inset-6xl) * 2);
			text-align: left;
		}
	}

	.reviewHeading {
		color: var(--text-yellow) !important;
		@media screen and (min-width: variables.$breakpoint-md) {
			width: 30%;
		}
	}
	.reviewText {
		color: var(--text-light);
		font-weight: 400;
		text-align: left;
		@media screen and (min-width: variables.$breakpoint-md) {
			width: 70%;
		}
	}
	.review {
		margin-bottom: var(--space-inset-lg);
		font-size: 1.15rem;
		line-height: 1.5rem;
		&::before,
		&::after {
			content: '"';
		}
	}
}
