@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	align-items: center;
	min-height: 60rem;

	.content {
		.heading {
			--title-color: var(--text-light);
		}
		.text {
			--body-color: var(--text-light);
		}

		.linksContainer {
			margin-top: var(--space-stack-3xl);

			.listContainer {
				position: relative;
				.verticalLine {
					position: absolute;
					left: 0;
				}
				.list {
					margin: 0;
					padding: var(--space-stack-md) 0 var(--space-stack-md) var(--space-inset-xl);
					list-style-type: none;

					.listItem {
						margin-bottom: var(--space-stack-xs);

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		.content {
			max-width: 50%;
		}
	}
}
