@use 'src/styles/variables' as variables;

.card {
	background-color: var(--bg-dark-green-full);
}

.bgColor {
	background-color: var(--bg-dark-green-full);
}

.titleColor {
	--title-color: var(--text-mint-green);
	color: var(--title-color);
}

.smallTitleColor {
	color: var(--text-mint-green);
}

.textColor {
	--body-color: var(--text-mint-green);
	color: var(--body-color);
}

.accentTextColor {
	color: var(--text-pink-full);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--primary-yellow);
}

.antiIconColor,
.uspIconColor,
.iconColor {
	color: var(--icon-color);
	--icon-color: var(--text-mint-green);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--bg-white);
	color: var(--primary-dark-green);

	&:hover {
		background: var(--bg-white);
		color: var(--primary-dark-green);
	}
}

.buttonOutline {
	border-color: var(--text-mint-green);
	background: transparent;
	color: var(--text-mint-green);

	&:hover {
		border-color: var(--text-mint-green) !important;
		background: transparent;
		color: var(--text-mint-green);
	}
}

.textLink {
	--link-hover-effect-color: var(--text-mint-green);
	color: var(--text-mint-green);

	&:hover {
		color: var(--text-mint-green);
	}
}

// table styles
.row {
	background-color: var(--disabled-color);
}

.column {
	background-color: var(--bg-light-grey);
}

.borderColor.borderColor.borderColor {
	border-color: var(--border-color);
}

.stickerBgColor {
	background-color: var(--border-color-blue);
}
