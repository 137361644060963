@use 'src/styles/variables' as variables;

.container {
	margin: 0 auto;
	max-width: 60rem;
}

.marginBottom {
	margin-bottom: var(--space-stack-3xl);
}

.center {
	margin-top: var(--space-stack-3xl);
	text-align: center;

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-xl);
	}
}
