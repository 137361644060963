@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex: 1 1 20rem;
	flex-direction: column;
}

.noBackground {
	background-color: transparent !important;
}

.content {
	z-index: var(--z-index-2);
	.inner {
		max-width: var(--text-max-width);
	}

	.linkButtons {
		display: flex;
		gap: var(--space-inset-md) var(--space-inset-xl);

		@media (max-width: variables.$breakpoint-md) {
			flex-direction: column;
		}

		a {
			@media (max-width: variables.$breakpoint-md) {
				width: 100%;
			}
		}
	}
}
