.container {
	width: 100%;

	.labelContainer {
		display: flex;
		justify-content: space-between;
		margin-bottom: var(--space-inset-sm);
	}
}

.sliderContainer {
	display: flex;
	position: relative;
	align-items: center;
	width: 100%;
	input[type='range'] {
		-webkit-appearance: none;
		appearance: none;
		z-index: 2;
		cursor: pointer;
		background: transparent;
		width: 100%;
	}

	input[type='range']:focus {
		outline: none;
	}

	input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		border-radius: var(--border-radius-round);
		background-color: var(--slider-color);
		width: 1.25rem;
		height: 1.25rem;
	}

	.trackAfter {
		position: absolute;
		z-index: 1;
		border-radius: 0.25rem;
		width: 100%;
		height: 0.25rem;
	}
	.progressTrack {
		position: absolute;
		z-index: 2;
		border-radius: 0.25rem;
		width: 100%;
		height: 0.375rem;
	}
}
