@use 'src/styles/variables' as variables;

.filterContainer {
	display: flex;
	justify-content: center;
	gap: var(--space-stack-md) var(--space-inset-xl);
	margin-bottom: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
	}
}

.filterField {
	@media (min-width: variables.$breakpoint-md) {
		max-width: 20rem;
	}
}

.jobList {
	display: flex;
	flex-direction: column;
	gap: var(--space-inline-xl);
	margin-bottom: var(--space-stack-3xl);
}
