@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	flex-wrap: wrap;

	@media (min-width: variables.$breakpoint-lg) {
		&:not(.hasSubMenuV1) {
			min-height: 95vh;
		}
	}
}

.content {
	align-self: center;

	@media (max-width: variables.$breakpoint-sm) {
		.inner {
			margin-top: var(--space-stack-2xl);
			width: 100%;
		}
	}
}

.tags {
	margin-bottom: var(--space-stack-xl);
}

.scrollSection {
	align-self: flex-end;
	padding-top: var(--space-stack-3xl);
	width: 100%;

	@media (max-width: variables.$breakpoint-lg) {
		padding-top: var(--space-stack-xl);
	}

	.scrollButton {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		border: none;
		background: none;
		color: var(--text-light);
	}

	.scrollIcon {
		margin-right: 1rem;
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		&.hasSubMenuV1 {
			padding-top: 4rem;
		}
	}
}

.btn {
	@media (max-width: variables.$breakpoint-md) {
		width: 100%;
	}
}
