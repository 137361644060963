@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.image {
	position: relative;
	overflow: hidden;
	@media (min-width: variables.$breakpoint-md) {
		border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
		min-height: 14rem;
	}
}

.indexPage {
	.image {
		min-height: 28rem;
	}
}

.card {
	display: grid;
	position: relative;
	grid-auto-rows: 1fr;
	height: 100%;
	overflow: hidden;
}

.content {
	padding: var(--space-stack-xl);
}

.smallTitle {
	--title-color: var(--primary-green);

	.tag {
		color: var(--primary-green);
	}
	.verticalSeparator {
		padding: 0 0.5rem;
	}
}

.published {
	color: var(--custom-text-placeholder);
}

//GROUP ARTICLES
.article--1,
.article--2,
.article--3 {
	div:nth-of-type(2):not(.title) {
		border: functions.rem(1px) solid var(--black-16-alpha);
		@media (min-width: variables.$breakpoint-md) {
			border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
		}
	}
}

//GRID ARTICLES
.grid-article1,
.grid-article2,
.grid-article3,
.grid-articles {
	&--1,
	&--2,
	&--3 {
		div:nth-of-type(2):not(.title) {
			border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
			background-color: var(--bg-beige-light);
		}
	}
}

.grid-article1 {
	&--2 {
		div:nth-of-type(1) {
			position: relative;
			overflow: hidden;
			@media (min-width: variables.$breakpoint-lg) {
				border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
			}
		}
		div:nth-of-type(2) {
			border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
			@media (min-width: variables.$breakpoint-lg) {
				order: -1;
				border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
			}
		}
	}
}

.grid-article2 {
	&--1 {
		div:nth-of-type(1) {
			position: relative;
			border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
			overflow: hidden;
		}
		@media (min-width: variables.$breakpoint-lg) {
			div:nth-of-type(1) {
				border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
			}
			div:nth-of-type(2) {
				order: -1;
				border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
			}
		}
	}
}
