/*
 * This theme is used to set colors of elemets on images, DO NOT set any background color in this
 * theme file
 */

.titleColor {
	--title-color: var(--text-heading-color);
	color: var(--title-color);
}

.smallTitleColor {
	color: var(--text-dark);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}
.brandlineColor {
	--brand-stripe-1-bg: var(--primary-yellow);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--primary-live-green);
	color: var(--primary-dark-green);

	&:hover {
		background: var(--bg-white);
		color: var(--primary-dark-green);
	}
}

.buttonOutline {
	border-color: var(--primary-live-green);
	background: transparent;
	color: var(--primary-live-green);

	&:hover {
		border-color: var(--text-light) !important;
		background: transparent;
		color: var(--text-light);
	}
}

.textLink {
	--link-hover-effect-color: var(--primary-live-green);
	color: var(--primary-live-green);

	&:hover {
		color: var(--primary-live-green);
	}
}

// LinkToggle
.linkToggleInactive {
	color: var(--text-light);

	&:hover {
		color: var(--text-light);
	}
}

.linkToggleActive {
	background-color: var(--bg-white);
	color: var(--text-primary-green);
}

.linkToggleBgColor {
	background-color: var(--bg-green-full);
}
