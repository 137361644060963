.container {
	position: relative;
	border: none;
	height: 100%;
}

// Borders
.border--true {
	border: 1px solid var(--border-color);
}

.border--false {
	border: none;
}

.border-rounded--true {
	border-radius: var(--border-radius-md);
}

// Layout
.layout--center {
	text-align: center;
}

// Padding
.padding--none {
	padding: 0;
}

.padding--xs {
	padding: var(--space-inset-xs);
}

.padding--sm {
	padding: var(--space-inset-sm);
}

.padding--md {
	padding: var(--space-inset-md);
}

.padding--lg {
	padding: var(--space-inset-lg);
}
.padding--xl {
	padding: var(--space-inset-xl);
}

.noBackground {
	border: none;
	background-color: transparent !important;
}
