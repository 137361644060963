@use 'src/styles/variables' as variables;

.promotorBubble {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 50%;
	background-color: var(--primary-live-green);
	width: 14rem;
	height: 14rem;
	text-align: center;

	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}

	.title {
		--title-color: var(--text-light);
	}
}
