.icon {
	align-self: center;
}

// DEFAULT THEME

.iconColor {
	--icon-color: var(--text-dark);
	color: var(--icon-color);
}

// SIZE

.fa-1x {
	width: 1rem;
	min-width: 1rem;
	height: 1rem;
	font-size: 1rem;
}

.fa-2x {
	width: 2rem;
	min-width: 2rem;
	height: 2rem;
	font-size: 2rem;
}

.fa-3x {
	width: 3rem;
	min-width: 3rem;
	height: 3rem;
	font-size: 3rem;
}

.fa-4x {
	width: 4rem;
	min-width: 4rem;
	height: 4rem;
	font-size: 4rem;
}

.fa-5x {
	width: 5rem;
	min-width: 5rem;
	height: 5rem;
	font-size: 5rem;
}

.fa-6x {
	width: 6rem;
	min-width: 6rem;
	height: 6rem;
	font-size: 6rem;
}

.fa-7x {
	width: 7rem;
	min-width: 7rem;
	height: 7rem;
	font-size: 7rem;
}

.fa-8x {
	width: 8rem;
	min-width: 8rem;
	height: 8rem;
	font-size: 8rem;
}

.fa-9x {
	width: 9rem;
	min-width: 9rem;
	height: 9rem;
	font-size: 9rem;
}

.fa-10x {
	width: 10rem;
	min-width: 10rem;
	height: 10rem;
	font-size: 10rem;
}

.fa-xs {
	width: 0.75rem;
	min-width: 0.75rem;
	height: 0.75rem;
}

.fa-sm {
	width: 1.25rem;
	min-width: 1.25rem;
	height: 1.25rem;
}

.fa-lg {
	width: 1.75rem;
	min-width: 1.75rem;
	height: 1.75rem;
}

.fa-rotate-90 {
	transform: rotate(90deg);
}

.fa-rotate-180 {
	transform: rotate(180deg);
}

.fa-rotate-270 {
	transform: rotate(270deg);
}

.customIconContainer {
	display: flex;
	position: relative;
	justify-content: center;
}
