@use 'src/styles/variables' as variables;

.container {
	color: var(--text-heading-color);
}

.group1,
.group2 {
	@media (min-width: variables.$breakpoint-md) {
		gap: var(--space-stack-xl);
		margin-bottom: var(--space-stack-xl);
	}

	@media (min-width: variables.$breakpoint-lg) {
		gap: var(--space-stack-xl);
		margin-bottom: var(--space-stack-xl);
	}
}

.group1 {
	article:nth-of-type(1) {
		grid-column: span 1;
		@media (min-width: variables.$breakpoint-md) {
			grid-column: span 2;
		}
		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 4;
			h5 {
				font-size: var(--font-size-h4);
			}
		}
	}

	article:nth-of-type(2) {
		grid-column: span 1;
		@media (min-width: variables.$breakpoint-md) {
			grid-column: span 2;
		}
		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 2;
		}
	}
}

.group2 {
	article:nth-of-type(1) {
		grid-column: span 1;
		@media (min-width: variables.$breakpoint-md) {
			grid-column: span 2;
		}
		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 2;
		}
	}

	article:nth-of-type(2) {
		grid-column: span 1;

		@media (min-width: variables.$breakpoint-md) {
			grid-column: span 2;
		}
		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 4;
			h5 {
				font-size: var(--font-size-h4);
			}
		}
	}
}

.group3 {
	grid-column: span 1;
	@media (min-width: variables.$breakpoint-md) {
		gap: var(--space-stack-xl);
		margin-bottom: var(--space-stack-xl);
	}

	@media (min-width: variables.$breakpoint-lg) {
		gap: var(--space-stack-xl);
		margin-bottom: var(--space-stack-xl);
	}
}

.header {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	padding: var(--space-inset-xl);

	@media (min-width: variables.$breakpoint-md) {
		margin-bottom: var(--space-stack-2xl);
		padding: 0;
	}

	@media (min-width: variables.$breakpoint-lg) {
		flex-wrap: nowrap;
	}

	.stripes {
		margin-right: var(--space-inset-xl);
		width: 100%;
	}
}
