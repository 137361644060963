.container {
	color: var(--text-heading-color);
}

.subtitle {
	text-align: center;
}

.notFoundText {
	color: var(--custom-text-placeholder);
	text-align: center;
}

.categoryDescription {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.noIntegrations {
	max-width: 43.75rem;
}
