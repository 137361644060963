@use 'src/styles/variables' as variables;

.selectedFilters {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: var(--space-inline-md);
	min-height: var(--space-stack-2xl);
	@media (max-width: variables.$breakpoint-md) {
		gap: var(--space-inline-xs);
		margin-top: var(--space-stack-xs);
	}
}
.hiddenFilter {
	@media (max-width: variables.$breakpoint-md) {
		display: none;
	}
}
.title {
	font-weight: 700;
}
.filterItem {
	display: flex;
	align-items: center;
	gap: var(--space-inline-xs);
	border: 2px solid var(--action-color);
	border-radius: var(--border-radius-3xl);
	padding: var(--space-inset-md);
	height: 2.5rem;
	color: var(--text-primary-green);
	font-weight: 700;
	font-size: var(--font-size-small);
	@media (max-width: variables.$breakpoint-md) {
		height: 2.25rem;
	}
}

.icon {
	display: flex;
	cursor: pointer;
	width: 1.25rem;
	height: 1.25rem;
	color: var(--text-primary);
	font-style: normal;
	font-weight: 300;
}
