.bgColor {
	background-color: var(--bg-red-light);
}

.titleColor {
	--title-color: var(--text-dark);
	color: var(--title-color);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--text-dark);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--primary-green);
	color: var(--text-light);

	&:hover {
		background: var(--bg-dark-green-full);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--primary-green);
	background: transparent;
	color: var(--primary-green);

	&:hover {
		border-color: var(--text-dark) !important;
	}
}

.textLink {
	color: var(--primary-green);

	&:hover {
		color: var(--primary-green);
	}
}

.careerButton {
	border-color: var(--border-color-red);
	background: var(--bg-red-light);
}
