.bgColor {
	background-color: var(--accent-orange);
}

.textColor {
	--body-color: var(--text-light);
	color: var(--body-color);
}

.iconColor {
	--icon-color: var(--text-light);
}

.textLink {
	--link-hover-effect-color: var(--text-light);
	color: var(--text-light);

	&:hover {
		color: var(--text-light);
	}
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--accent-orange);
	color: var(--text-light);

	&:hover {
		background: var(--accent-orange);
		color: var(--text-light);
	}
}
