@use 'src/styles/variables' as variables;

.dropzone {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: var(--space-stack-md);
	border: 1px dashed var(--border-color);
	border-radius: var(--border-radius-md);
	background: var(--selected-color);
	padding: var(--space-inset-xl);
	&.active {
		background: var(--primary-green);
	}
}

.text {
	margin: 0;
}

.uploadedFiles {
	width: 100%;
}

.list {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style-type: none;

	.listItem {
		display: flex;
		align-items: center;
		margin-bottom: var(--space-stack-md);

		&.rejected {
			display: block;
			color: var(--danger-color);

			.error {
				margin-left: 1.75rem;
				width: 100%;
				font-size: small;
			}
		}

		.icon {
			margin-right: var(--space-inset-sm);
		}

		.leftColumn {
			display: flex;
			align-items: center;

			@media (min-width: variables.$breakpoint-lg) {
				width: 70%;
			}
		}

		.textContainer {
			max-width: 80%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.deleteFile {
			display: flex;
			align-items: center;
			margin-left: var(--space-inset-md);
			border: 0;
			background: none;
			padding: 0;
		}
	}
}
