@use 'src/styles/variables' as variables;

.searchField {
	margin-bottom: var(--space-stack-3xl);
}

.alphabetContainer {
	margin-bottom: var(--space-stack-xl);
}

.alphabet {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	color: var(--primary-green);
}

.link {
	margin-right: var(--space-inset-xl);
	color: var(--primary-green);
	font-weight: 700;
	text-decoration: none;

	&:not([href]) {
		opacity: 0.25;
	}
}

.list {
	margin-bottom: var(--space-stack-3xl);
	scroll-margin-top: 6rem;
}

.headerLetter {
	background-color: var(--bg-dark-green-full);
	padding: var(--space-inset-xl);
	color: var(--text-light);

	@media (min-width: variables.$breakpoint-md) {
		border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
	}

	.letter {
		font-weight: 700;
		font-size: 1.5rem;
	}
}

.content {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-areas: 'a1' 'a2' 'a3';
	column-gap: 3rem;
	align-items: center;
	padding: var(--space-inset-xl);
	@media (min-width: 62rem) {
		grid-template-columns: repeat(5, 1fr);
		grid-template-areas: 'a1 a2 a2 a2 a3';
	}
	&.itemBeige {
		background-color: var(--primary-beige-25);
	}
	&.itemLightBeige {
		background-color: var(--bg-beige-light);
	}

	.postTitle {
		--title-color: var(--primary-green);
		grid-area: a1;
	}

	.intro {
		grid-area: a2;
		margin: 0;
		margin-bottom: 1rem;
		padding-top: 1rem;
		@media (min-width: 62rem) {
			margin-bottom: 0;
			padding-top: 0;
		}
	}

	.linkButton {
		--body-color: var(--primary-green);
		--text-color: var(--text-light);
		grid-area: a3;

		@media (min-width: 62rem) {
			justify-self: flex-end;
		}
	}
}
