.checkboxContainer {
	max-width: 100%;

	.checkboxGrid {
		.checkbox {
			justify-self: left;
			margin-right: var(--space-stack-xl);
			white-space: nowrap;
		}
	}
}
