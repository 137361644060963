@use 'src/styles/variables' as variables;

.inner {
	z-index: var(--z-index-1);
	max-width: var(--text-max-width);
}

.linkButtons {
	display: flex;
	gap: var(--space-stack-lg) var(--space-inline-xl);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
	}

	a {
		@media (max-width: variables.$breakpoint-md) {
			width: 100%;
		}
	}
}

.layout--center {
	@media (min-width: variables.$breakpoint-md) {
		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		.inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: var(--text-max-width);
		}
	}
}

.hasSubMenuV1 {
	.content {
		@media (max-width: variables.$breakpoint-md) {
			padding-top: 4rem;
		}
	}

	.section.section.section {
		&.theme--white {
			@media (min-width: variables.$breakpoint-lg) {
				padding-top: 0;
			}
		}
	}

	.inner {
		max-width: var(--text-max-width-sub-menu);
	}
}

// Compact style
.compact {
	.inner {
		padding: var(--space-inset-xl) 0 0;
		max-width: 100%;

		@media (min-width: variables.$breakpoint-xl) {
			max-width: 75%;
		}
	}
}
