@use 'src/styles/variables' as variables;

.topSection {
	padding-top: 0;
}

.filterHeading {
	margin-bottom: var(--space-stack-xl);
}

.filterSection {
	margin-bottom: var(--space-stack-lg);
}

.notFoundText {
	color: var(--custom-text-placeholder);
	text-align: center;
}

.sectionNav {
	position: -webkit-sticky;
	position: sticky;
	top: 2.5rem;
	transition: all 0.5s;
	padding: var(--space-inset-3xl);
	padding-left: 0;

	@media (max-width: variables.$breakpoint-xl) {
		display: none;
	}
}
