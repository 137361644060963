@use 'src/styles/variables' as variables;

.container {
	&.layout--center {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.title,
		.text {
			@media (min-width: variables.$breakpoint-xl) {
				max-width: 55%;
			}
		}

		.text {
			@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-xl) {
				padding: 0 var(--space-inset-2xl);
			}
		}

		.footer {
			flex-direction: column;
			gap: var(--space-inset-md);

			.info {
				justify-content: center;
				text-align: center;
			}
		}
	}
}

.margin-bottom--lg {
	margin-bottom: var(--space-stack-lg);
}

.margin-bottom--xl {
	margin-bottom: var(--space-stack-xl);
}

.margin-bottom--3xl {
	margin-bottom: var(--space-stack-3xl);
}

.image {
	position: relative;
	margin-bottom: var(--space-stack-xl);
	min-height: 18rem;
}

.text {
	h2:not(:first-of-type),
	h3:not(:first-of-type),
	h4:not(:first-of-type),
	h5:not(:first-of-type) {
		margin-top: 2rem;
	}
}

.footer {
	display: flex;
	gap: var(--space-inset-xl);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
		width: 100%;

		span {
			flex-grow: 1;
		}
	}

	&.flexDirectionColumn {
		flex-direction: column;
		gap: var(--space-inset-md);
	}

	.info {
		display: flex;
		align-items: center;
		p {
			margin: 0;
		}
	}
}

.linkButtons {
	display: flex;
	flex-wrap: wrap;
	gap: var(--space-inset-md) var(--space-inset-xl);
}

.adjustableTextWidth {
	.title,
	.text {
		max-width: var(--text-max-width);
	}
}
