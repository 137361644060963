/* stylelint-disable no-duplicate-selectors */
@use 'sass:math';
@use 'src/styles/functions' as functions;

/**
 * 30rem: minimum width each for two columns in first card
 * 68rem: 4+30+30+4
 * 48rem
 * 4rem inset for first two cards except on mobile
 * 2rem inset for remaining cards
 * 1rem stack between p tag
 */

.group1,
.group2 {
	display: grid;
	column-gap: 1rem;

	@media (min-width: 32rem) {
		row-gap: 2rem;
	}
	@media (min-width: 48rem) {
		row-gap: 1rem;
	}
}

/**
 * Fancy grid of first 4 articles
 */
.group1 {
	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4'
		'l1'
		'l2';
	.article1 {
		grid-area: a1;
	}
	.article2 {
		grid-area: a2;
	}
	.article3 {
		grid-area: a3;
	}
	.article4 {
		grid-area: a4;
	}
	.linkCard1 {
		grid-area: l1;
	}
	.linkCard2 {
		grid-area: l2;
	}

	@media (min-width: 48rem) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'a1 a1'
			'a2 a2'
			'a3 a4'
			'l1 l2';
	}
	@media (min-width: 64rem) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'a1 a1 a1 a1'
			'a2 a2 a3 l2'
			'a2 a2 a3 a4'
			'a2 a2 l1 a4';
	}

	@media (min-width: 32rem) {
		margin-bottom: 2rem;
	}
	@media (min-width: 48rem) {
		margin-bottom: 1rem;
	}

	.article1 {
		grid-area: a1;

		p {
			margin: 2rem 0;
		}

		.heading {
			font-size: clamp(var(--font-size-h4), 4vw, var(--font-size-h2));
		}

		@media (min-width: 48rem) {
			grid-template-rows: initial;
			grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));

			.content {
				order: -1;
				padding: 4rem;
			}
		}
		@media (min-width: 48rem) and (max-width: (functions.$html-font-size * 64 - 1)) {
			grid-template-rows: initial;
		}
		@media (min-width: 64rem) {
			height: 37rem;
		}
	}
	.article2 {
		@media (min-width: 48rem) and (max-width: 64rem) {
			grid-template-rows: initial;
			grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));

			.content {
				padding: 4rem;
			}
		}
	}
}

/**
 * 1-3 columns for remaining articles
 */
.group2 {
	grid-template-columns: repeat(1, 1fr);

	@media (min-width: 48rem) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 64rem) {
		grid-template-columns: repeat(3, 1fr);
	}

	.card {
		grid-template-rows: 1fr 1fr;
		background-color: var(--bg-beige-light);
	}
}

.card,
.linkCard1,
.linkCard2 {
	@media (min-width: 32rem) {
		border-radius: var(--border-radius-md);
	}
}

.linkCard1 {
	@media (max-width: (functions.$html-font-size * 32 - 1)) {
		margin: 2rem;
	}
}
.linkCard2 {
	@media (max-width: (functions.$html-font-size * 32 - 1)) {
		margin: 0 2rem 2rem;
	}
}

.linkCard1,
.linkCard2 {
	display: flex;
	align-items: flex-end;
	background-color: var(--bg-beige-light);
	padding: 2rem;

	a {
		padding: 0;
	}
}

.card {
	display: grid;
	grid-template-rows: 2fr 3fr;
	background-color: var(--bg-beige-light);
	overflow: hidden;
}

.image {
	position: relative;
}

.content {
	padding: 2rem;

	@media (max-width: (32rem - 1)) {
		padding-bottom: 4rem;
	}

	p {
		margin: 1.5rem 0;
	}
}

.heading {
	font-size: var(--font-size-h4);
}
