@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex-direction: column;
	border-radius: var(--border-radius-md);
	padding: var(--space-inset-lg);
	width: 100%;
	text-align: left;
}

.outerContainer {
	height: 100%;
}

.addSpace {
	padding: var(--space-stack-md) 0;
	width: 100%;
	height: 100%;
}

.secondary,
.primary {
	align-self: center;
	width: 100%;
	height: 100%;
}

.productHeading {
	font-weight: 700;
	hyphens: auto;
	@media (min-width: variables.$breakpoint-sm) {
		font-size: 1.8rem !important;
	}
}

.description {
	margin-top: var(--space-stack-md);
}

.price {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.crossedOutPriceContainer {
	display: flex;
	align-items: baseline;
	clear: both;
}

.crossedOutPrice {
	margin-right: var(--space-inline-xs);
	padding-bottom: 0;
	font-weight: 400;
	line-height: 1;
	text-decoration: line-through;
}

.firstPriceText {
	margin-bottom: 0;
}

.showContentButton {
	border-bottom: 1px solid transparent !important;
	text-decoration: none;

	span::after {
		content: none !important;
	}
}

.showContentIcon {
	vertical-align: middle !important;
	margin-left: var(--space-inset-xs);
}

.centerButton {
	margin-bottom: var(--space-stack-xl);
	text-align: center;
}

.descriptionText {
	margin-bottom: var(--space-stack-md);
}

.lineClamp {
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.line {
	margin: var(--space-stack-lg) 0;
	width: 100%;
	&.primaryLine {
		opacity: 0.2;
	}
}

.priceAndBtnContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: auto;

	.button {
		margin-top: var(--space-stack-lg);
		width: 100%;
		white-space: nowrap;
	}
}

.readMoreButton.readMoreButton.readMoreButton {
	margin-top: var(--space-stack-md);
	text-align: center;

	a {
		margin-right: 0;
	}
}

.termsText {
	white-space: pre-wrap;
}

.bold.bold {
	font-weight: 700;
}

.packageProductNames {
	display: grid;
	grid-template-columns: 100%;
	margin-bottom: var(--space-stack-lg);
	grid-gap: var(--space-stack-3xs);
}

.customPriceLargeFont div div {
	div {
		font-size: 50px;
		@media (max-width: variables.$breakpoint-sm) {
			font-size: 42px;
		}
	}

	span {
		line-height: 25px;
	}
}

.customPriceMediumFont div div {
	div {
		font-size: 28px;
		line-height: 32px;
	}

	span {
		line-height: 23px;
	}
}
