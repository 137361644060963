.contactContainer {
	animation: fadeIn 1s;
	margin-top: auto;
	height: 9rem;

	.addressRow {
		display: flex;

		.mapIcon {
			margin-left: var(--space-inline-xs);
		}
	}

	.contact {
		margin-bottom: var(--space-stack-md);
		padding: 0;
		list-style-type: none;
		text-align: left;

		.listItem {
			display: flex;
			margin-bottom: var(--space-stack-2xs);

			.text {
				word-break: break-word;
			}

			.iconContainer {
				margin-right: var(--space-stack-md);
			}
		}
	}
}
