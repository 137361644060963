@use 'src/styles/functions' as functions;

.line {
	float: left;
	margin-right: var(--space-inline-xl);
	border-radius: 1rem;
	background-color: var(--brand-stripe-2-bg);
	width: functions.rem(4px);
	height: 100%;
	min-height: 4rem;
}
