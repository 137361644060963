.container {
	--brand-stripe-2-bg: var(--text-yellow);
	height: 100%;

	.inner {
		position: relative;

		.verticalLine {
			position: absolute;
			left: 0;
		}

		.list {
			margin: 0;

			list-style-type: none;

			&:not(.noPadding) {
				padding: var(--space-stack-md) 0 var(--space-stack-md) var(--space-inset-xl);

				&.mobile {
					padding: var(--space-stack-md) var(--space-stack-xl) var(--space-inset-3xl) var(--space-stack-xl);
				}
			}

			a {
				margin-right: 0;
			}

			.listItem {
				margin-bottom: var(--space-stack-xs);

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

.theme--white {
	--body-color: var(--text-dark);
	--icon-color: var(--text-heading-color);
}

.theme--light-green {
	--body-color: var(--text-dark);
	--icon-color: var(--text-heading-color);
}

.theme--yellow {
	--body-color: var(--text-dark);
	--icon-color: var(--text-heading-color);
}

.theme--green {
	--body-color: var(--text-light);
	--icon-color: var(--text-yellow);
}

.theme--dark-green {
	--body-color: var(--text-light);
	--icon-color: var(--text-yellow);
}

.theme--purple {
	--body-color: var(--text-light);
	--icon-color: var(--text-yellow);
}

.theme--back-yellow {
	--body-color: var(--text-dark);
	--brand-stripe-2-bg: var(--text-dark);
}
