@use 'src/styles/functions' as functions;

.card {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.textContainer {
	display: flex;
	flex-direction: column;
	padding-left: var(--space-inset-lg);
}

.imageContainer {
	position: relative;
	min-width: functions.rem(140px);
	max-width: functions.rem(140px);
}
