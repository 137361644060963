@use 'src/styles/functions' as functions;
@use 'src/styles/animations' as animations;
@use 'src/styles/variables' as variables;

.menuContainer {
	border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
	background-color: var(--primary-green);
	padding: var(--space-inset-md);
	color: var(--text-light);

	button {
		display: flex;
		align-items: center;
		border: none;
		background: 0;
		padding: 0;
		min-width: functions.rem(100px);
		color: var(--text-light);
		font-weight: 700;

		.icon {
			margin-left: 0.5rem;
			width: 0.5rem;
		}
	}

	.links {
		margin: 0;
		margin-top: var(--space-stack-md);
		padding: 0;
		list-style-type: none;

		li {
			margin-bottom: var(--space-stack-xs);
		}

		a {
			color: var(--text-light);
			text-decoration: none;
		}

		&.open {
			animation-duration: 0.8s;
			animation-name: fadeIn;
		}

		&.closed {
			display: none;
		}
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.menuContainer {
		padding: var(--space-inset-md);
		width: 100%;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.menuContainer {
		display: none;
	}
}
