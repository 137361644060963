.list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	.listItem {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.listItem:last-child {
		margin-bottom: 0;
	}

	.listItem .icon {
		margin-right: 1rem;
	}
}
