@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/*
	Slide in animation from RIGHT
*/
@keyframes slideInFromRight {
	0% {
		margin-left: 100%;
		width: 100%;
	}

	100% {
		margin-left: 0%;
		width: 100%;
	}
}

/*
	Slide in animation from LEFT
*/
@keyframes slideInFromLeft {
	0% {
		margin-left: -100%;
		width: 100%;
	}

	20% {
		margin-left: -30%;
		width: 100%;
	}

	100% {
		margin-left: 0;
		width: 100%;
	}
}

.animation {
	animation: fadeIn ease 0.5s;
	animation-iteration-count: 1;
}
