@use 'src/styles/variables' as variables;

.container {
	position: relative;
	overflow: auto;
}

.float--right {
	float: right;
	padding-left: var(--space-inset-2xl);
}

.float--left {
	float: left;
	padding-right: var(--space-inset-2xl);
}

.image {
	margin-bottom: var(--space-stack-md);
	width: 33%;

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: var(--space-stack-xl);
		padding: 0;
		width: 100%;
	}
}
