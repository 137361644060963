@use 'src/styles/variables' as variables;

.layout--image-right {
	.image {
		@media (min-width: variables.$breakpoint-lg) {
			margin: 0 0 0 auto;
		}
	}

	.image div {
		@media (min-width: variables.$breakpoint-lg) {
			border-radius: 31.25rem 0 0 31.25rem;
		}
	}

	.textColumn {
		order: 1;
	}

	.imageColumn {
		order: 2;
	}
}

.layout--image-left {
	.image div {
		@media (min-width: variables.$breakpoint-lg) {
			border-radius: 0 31.25rem 31.25rem 0;
		}
	}
}

.textColumn {
	align-self: center;
	padding: 0 var(--space-inset-5xl);

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inset-xl) var(--space-inset-3xl) var(--space-inset-4xl) var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-xl) var(--space-inset-xl) var(--space-inset-3xl) var(--space-inset-xl);
	}
}

.image {
	position: relative;
	min-height: 25rem;

	@media (min-width: variables.$breakpoint-lg) and (max-width: 1600px) {
		max-width: 40rem;
	}

	@media (min-width: variables.$breakpoint-max) {
		max-width: 55rem;
		min-height: 50rem;
	}

	@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-max) {
		max-width: 45rem;
		height: 80vh;
		min-height: 32rem;
		max-height: 55rem;
	}

	@media (max-width: variables.$breakpoint-xl) {
		max-height: 20rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		min-height: 30rem;
		max-height: 30rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		min-height: 25rem;
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.layout--image-right {
		.textColumn {
			order: 2;
		}

		.imageColumn {
			order: 1;
		}
	}
}

.titleMobile {
	display: none;
	position: absolute;
	right: var(--space-inset-3xl);
	bottom: 0;
	left: var(--space-inset-3xl);
	z-index: var(--z-index-1);

	@media (max-width: variables.$breakpoint-md) {
		right: var(--space-inset-xl);
		left: var(--space-inset-xl);
	}

	@media (max-width: variables.$breakpoint-lg) {
		display: block;
	}
}

.titleDesktop,
.brandLine {
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.overlay {
	@media (max-width: variables.$breakpoint-lg) {
		position: absolute;
		inset: auto 0 0;
		background: linear-gradient(9deg, rgba(0, 0, 0, 0.41) 27%, rgba(0, 56, 36, 0) 65.5%);
		height: 17.25rem;
	}
}

.popUpBtn {
	margin-top: 2rem;
}

// Compact
.compact {
	.image {
		@media (min-width: variables.$breakpoint-lg) {
			height: 32rem;
			min-height: 32rem;
			max-height: 32rem;
		}

		@media (min-width: variables.$breakpoint-xl) {
			width: 54rem;
		}

		@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
			width: 35rem;
		}
	}
}

// With sub menu
.hasSubMenuV1 {
	.textColumn {
		@media (min-width: variables.$breakpoint-xl) {
			padding: 0 var(--space-inset-3xl);
		}
	}

	.image {
		@media (min-width: variables.$breakpoint-max) {
			min-height: 35rem;
		}
	}

	&.compact {
		.image {
			@media (min-width: variables.$breakpoint-xl) {
				width: auto;
			}
		}
	}
}
