@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;

	@media (min-width: variables.$breakpoint-lg) {
		position: relative;
	}
}

.backgroundImage {
	@media (max-width: variables.$breakpoint-lg) {
		position: relative;
		min-height: 25rem;
	}
}

.overlay {
	position: absolute;
	width: 46rem;

	@media (max-width: variables.$breakpoint-lg) {
		width: auto;
		height: 17.25rem;
	}
}

.imageCaption {
	position: absolute;
	bottom: var(--space-inset-3xl);

	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.textContainer.textContainer.textContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	opacity: 0.9;
	max-width: 60rem;
	height: auto;
	min-height: 50rem;

	@media (min-width: variables.$breakpoint-xl) and (max-width: variables.$breakpoint-max) {
		height: 80vh;
		min-height: 40rem;
		max-height: 55rem;
	}

	@media (min-width: 1025px) and (max-width: variables.$breakpoint-xl) {
		margin-top: var(--space-inset-4xl);
		margin-bottom: var(--space-inset-4xl);
	}

	@media (min-width: variables.$breakpoint-xl) {
		margin-top: var(--space-inset-5xl);
		margin-bottom: var(--space-inset-5xl);
	}

	@media (min-width: variables.$breakpoint-max) {
		max-width: 50%;
	}

	@media (max-width: 1600px) {
		max-width: 55rem;
	}

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 50rem;
		min-height: initial;
	}

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inset-xl) var(--space-inset-3xl) var(--space-inset-4xl) var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-xl) var(--space-inset-xl) var(--space-inset-3xl) var(--space-inset-xl);
	}
}

.layout--text-left {
	.textContainer {
		padding: var(--space-inset-6xl) var(--space-inset-6xl) var(--space-inset-6xl) var(--space-inset-5xl);

		@media (min-width: variables.$breakpoint-lg) {
			border-radius: 0 31.25rem 31.25rem 0;
		}

		@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
			padding: var(--space-inset-3xl) var(--space-inset-4xl) var(--space-inset-3xl) var(--space-inset-3xl);
		}

		@media (min-width: calc(variables.$breakpoint-max + 5px)) {
			padding-left: 10%;
		}
	}

	.overlay {
		top: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(306deg, rgba(0, 0, 0, 0.49) 19.1%, rgba(0, 56, 36, 0) 54.36%);

		@media (max-width: variables.$breakpoint-lg) {
			inset: auto 0 0;
			background: linear-gradient(9deg, rgba(0, 0, 0, 0.41) 27%, rgba(0, 56, 36, 0) 65.5%);
		}
	}

	.imageCaption {
		right: var(--space-inset-3xl);
	}
}

.layout--text-right {
	.textContainer {
		padding: var(--space-inset-6xl) var(--space-inset-5xl) var(--space-inset-6xl) 14.5625rem;

		@media (min-width: calc(variables.$breakpoint-lg + 1px)) {
			margin: 0 0 0 auto;
			border-radius: 31.25rem 0 0 31.25rem;
		}

		@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
			padding: var(--space-inset-3xl) var(--space-inset-2xl) var(--space-inset-3xl) var(--space-inset-7xl);
		}

		@media (min-width: variables.$breakpoint-xl) {
			padding: 0 8rem 0 14.5625rem;
		}

		@media (min-width: calc(variables.$breakpoint-max + 5px)) {
			padding-right: 10%;
		}
	}

	.overlay {
		inset: 0;
		background: linear-gradient(63deg, rgba(0, 0, 0, 0.87) 0.19%, rgba(0, 56, 36, 0) 38.67%);

		@media (max-width: variables.$breakpoint-lg) {
			inset: auto 0 0;
			background: linear-gradient(9deg, rgba(0, 0, 0, 0.41) 27%, rgba(0, 56, 36, 0) 65.5%);
		}
	}

	.imageCaption {
		left: var(--space-inset-3xl);
	}
}

.titleMobile {
	display: none;
	position: absolute;
	right: var(--space-inset-3xl);
	bottom: 0;
	left: var(--space-inset-3xl);
	z-index: var(--z-index-1);
	max-width: var(--text-max-width);

	@media (max-width: variables.$breakpoint-lg) {
		right: var(--space-inset-3xl);
		left: var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		right: var(--space-inset-xl);
		left: var(--space-inset-xl);
	}

	@media (max-width: variables.$breakpoint-lg) {
		display: block;
	}
}

.headerCommon {
	@media (max-width: variables.$breakpoint-lg) {
		max-width: var(--text-max-width);
	}
}

.titleDesktop,
.brandLine {
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}
