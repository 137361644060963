@use 'src/styles/variables' as variables;

.container {
	header {
		width: 100%;
	}
}

.hasSubMenuV1 {
	.backgroundImage {
		img {
			border-radius: var(--border-radius-4xl);
		}
	}
}

.content {
	position: relative;
	z-index: var(--z-index-4);
}

.container:not(.hasSubMenuV1) {
	.content {
		@media (min-width: variables.$breakpoint-xl) {
			width: 100%;
			height: 100%;
			min-height: 65vh;
		}

		@media (min-width: variables.$breakpoint-max) {
			min-height: 56rem;
		}
	}
}

.headerContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: var(--space-stack-3xl);
	text-align: center;

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-3xl);
	}

	.linkButtons {
		padding: var(--space-inset-md) 0;

		@media (max-width: variables.$breakpoint-xl) {
			.button:first-of-type {
				margin-bottom: var(--space-inset-lg);
			}
		}

		@media (max-width: variables.$breakpoint-md) {
			width: 100%;
		}

		a {
			@media (min-width: 631px) {
				margin-right: var(--space-inset-xl);
			}

			@media (max-width: variables.$breakpoint-md) {
				margin-bottom: var(--space-inset-lg);
				width: 100%;
			}
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		.content {
			padding: var(--space-inset-3xl) var(--space-inset-md);
		}
	}

	.headerContent {
		align-items: flex-start;
	}
}
