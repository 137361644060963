.integrationCategory {
	cursor: pointer;
}
.categoryDescription {
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}
