.card {
	background-color: var(--bg-purple-light) !important;
}

.bgColor {
	background-color: var(--bg-purple-light) !important;
}

.titleColor {
	--title-color: var(--text-purple);
	color: var(--text-purple);
}

.smallTitleColor {
	color: var(--text-primary-green);
}

.textColor {
	color: var(--text-dark) !important;
}

.accentTextColor {
	color: var(--text-primary-green);
}

.uspIconColor {
	color: var(--icon-color);
	--icon-color: var(--primary-green);
}

.iconColor,
.antiIconColor {
	color: var(--icon-color);
	--icon-color: var(--text-dark);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--primary-green);
	color: var(--text-light);

	&:hover {
		background: var(--bg-dark-green-full);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--primary-green);
	background: transparent;
	color: var(--primary-green);

	&:hover {
		border-color: var(--primary-dark-green) !important;
	}
}

.textLink {
	--link-hover-effect-color: var(--primary-green);
	color: var(--primary-green);

	&:hover {
		color: var(--primary-green);
	}
}

// table styles
.borderColor {
	border-color: 1px solid var(--bg-purple-full) !important;
}

// LinkToggle
.linkToggleActive {
	background-color: var(--bg-white);
	color: var(--text-primary-green);
}

.linkToggleBgColor {
	background-color: var(--bg-green-full);
}

// Carousel indicators
.carouselIndicatorColor {
	--indicator-color: var(--action-color);
}
