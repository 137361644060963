@use 'sass:math';
@use 'src/styles/variables' as variables;

$icon-circle-size: 3.5rem;
$icon-circle-radius: math.div($icon-circle-size, 2);

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	container-name: container;
	container-type: inline-size;
}

.titleContainer {
	display: flex;
	justify-content: space-between;
}

.stepper {
	display: flex;
	flex-direction: column;
	margin-top: var(--space-stack-2xl);
	width: 100%;
}

.iconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	border: 2px solid var(--text-yellow);
	border-radius: 50%;
	background: var(--bg-white);
	width: $icon-circle-size;
	height: $icon-circle-size;
}

.stepContainer {
	display: block;
	margin: var(--space-stack-sm) 0;
	border-radius: var(--border-radius-md);
	background-color: var(--bg-yellow-light);
	padding: var(--space-inset-xl);
	width: 30rem;

	&.highlight {
		background-color: var(--bg-mint-green-full);
	}

	@container container (max-width: 36rem) {
		width: 20rem;
	}

	@container container (max-width: 24rem) {
		width: 18rem;
	}
}

.step {
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 6.25rem;

	&.position--right {
		justify-content: flex-end;
	}
}

.titleGrid.titleGrid.titleGrid,
.divider {
	@container container (max-width: 67rem) {
		display: none;
	}
}

.line {
	&-vertical {
		position: absolute;
		left: $icon-circle-radius;
		border-left: 2px solid var(--text-yellow);
		height: 100%;

		@container container (min-width: 67rem) {
			left: 50%;
		}
	}
	&-horizontal {
		position: relative;
		top: 0;
		border-top: 2px solid var(--text-yellow);
		width: calc(50% - 30rem - #{$icon-circle-radius});
		height: 0;

		@container container (max-width: 67rem) {
			width: calc(100% - 30rem - #{$icon-circle-radius});
		}

		@container container (max-width: 36rem) {
			width: calc(100% - 20rem - #{$icon-circle-radius});
		}

		@container container (max-width: 24rem) {
			width: calc(100% - 18rem - #{$icon-circle-radius});
		}
	}
}

.label {
	--title-color: var(--primary-green);
}

.linkButtons {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem 2rem;
}

.position--left {
	justify-content: flex-end;

	@container container (min-width: 50rem) {
		justify-content: space-between;
	}

	@container container (min-width: 67rem) {
		justify-content: flex-start;
		.stepContainer {
			order: 1;
		}
		.line {
			&-vertical {
				order: 4;
			}
			&-horizontal {
				order: 2;
			}
		}

		.iconContainer {
			order: 3;
		}
	}
}
