@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	border-radius: var(--border-radius-md);
	padding: var(--space-stack-xl);
	height: 100%;
}

.text {
	margin-bottom: var(--space-stack-lg);
	width: 100%;
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		padding: var(--space-stack-xl) var(--space-stack-md);
	}
}
