.card {
	background-color: var(--bg-purple-full);
}

.bgColor {
	background-color: var(--bg-purple-full);
}

.titleColor {
	--title-color: var(--text-light);
	color: var(--title-color);
}

.smallTitleColor {
	color: var(--text-light);
}

.textColor {
	--body-color: var(--text-light);
	color: var(--body-color);
}

.accentTextColor {
	color: var(--accent-live-green);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--bg-white);
}

.uspIconColor,
.iconColor,
.antiIconColor {
	color: var(--icon-color);
	--icon-color: var(--text-light);
}

.backgroundPattern {
	background: url('/patterns/fortnox-pattern-purple.svg');
	background-position: center;
	background-size: cover;
	background-color: var(--bg-purple-full);
}

.borderColor {
	--themed-border-color: var(--text-light);
	border-color: var(--themed-border-color);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--primary-live-green);
	color: var(--text-purple);

	&:hover {
		background: var(--text-light);
		color: var(--bg-purple-full);
	}
}

.buttonOutline {
	border-color: var(--primary-live-green);
	background: transparent;
	color: var(--primary-live-green);

	&:hover {
		border-color: var(--text-light);
		background: transparent;
		color: var(--text-light);
	}
}

.textLink {
	--link-hover-effect-color: var(--primary-live-green);
	color: var(--primary-live-green);

	&:hover {
		color: var(--primary-live-green);
	}
}

// LinkToggle
.linkToggleInactive {
	color: var(--text-dark);
}
.linkToggleBgColor {
	background-color: var(--bg-white);
}

.linkToggleActive {
	color: var(--text-dark);
}

// Carousel indicators
.carouselIndicatorColor {
	--indicator-color: var(--primary-live-green);
}
