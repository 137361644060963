@use 'src/styles/variables' as variables;

.equationGrid {
	@media (min-width: variables.$breakpoint-lg) {
		margin-left: var(--space-inset-2xl);
	}

	.gridItem {
		position: relative;
		@media (max-width: variables.$breakpoint-md) {
			margin-bottom: var(--space-stack-2xl);
		}
	}

	.gridItem:first-of-type {
		border-left: dotted 2px var(--primary-yellow);

		@media (max-width: variables.$breakpoint-md) {
			margin-top: var(--space-stack-3xl);
			border-top: dotted 2px var(--primary-yellow);
			border-left: none;
			padding-top: var(--space-stack-3xl);
			padding-left: 0;
		}

		@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
			margin-top: 0;
			border: none;
			border-left: none;
			padding-top: 0;
			padding-left: 0;
		}
	}

	@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
		margin-top: var(--space-stack-xl);
		border-top: dotted 2px var(--primary-yellow);
		padding-top: var(--space-stack-xl);
		padding-left: 0;
	}
}
