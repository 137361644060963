@use 'src/styles/variables' as variables;

.container {
	@media (min-width: variables.$breakpoint-lg) {
		display: grid;
		grid-template-rows: auto minmax(1.25rem, auto) auto auto;
	}
}

.gridContainer {
	height: auto;

	@media (max-width: variables.$breakpoint-sm) {
		display: none;
	}
}

.title {
	margin-bottom: var(--space-stack-md);

	@media (max-width: variables.$breakpoint-sm) {
		margin-bottom: var(--space-stack-xl);
	}
}

.subTitleWrapper {
	display: flex;
}

.tooltipWrapper {
	flex-grow: 1;
	margin-top: -0.25rem;
}

.tooltip.tooltip.tooltip {
	max-width: 34rem;

	@media (max-width: variables.$breakpoint-md) {
		width: auto;
		max-width: initial;
	}
}

.additionalText {
	margin-top: var(--space-stack-xl);
}
