@use 'src/styles/variables' as variables;

.btnContainer {
	display: flex;
	justify-content: center;
}

.jobContactTitle {
	@media (min-width: variables.$breakpoint-md) {
		text-align: right;
	}
}

.iconLinkContainer {
	@media (min-width: variables.$breakpoint-md) {
		float: right;
	}
}

.iconLink.iconLink.iconLink {
	justify-content: flex-start;
	margin-bottom: var(--space-stack-xs);

	a {
		margin-right: 0;
	}
}
