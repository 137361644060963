@use 'src/styles/variables' as variables;

.gridContainer {
	display: grid;
	column-gap: 2rem;
	row-gap: 2rem;
}

.images--1 {
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas: '. a a a a .';

	&.objectFitContain {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas: 'a';
	}

	.item--1 {
		min-height: 32rem;

		@media (max-width: variables.$breakpoint-lg) {
			min-height: var(--image-min-height-md);
		}

		@media (max-width: variables.$breakpoint-md) {
			min-height: var(--image-min-height-sm);
		}
	}

	@media (max-width: variables.$breakpoint-xl) {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas: 'a';
	}

	@media (max-width: variables.$breakpoint-md) {
		&:not(.objectFitContain) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				'a a'
				'b b'
				'c c';
		}
	}
}

.images--2 {
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas:
		'a a a b b b'
		'a a a b b b';

	.item--1,
	.item--2 {
		min-height: 32rem;

		@media (max-width: variables.$breakpoint-lg) {
			min-height: var(--image-min-height-md);
		}

		@media (max-width: variables.$breakpoint-md) {
			min-height: var(--image-min-height-sm);
		}
	}

	@media (max-width: variables.$breakpoint-lg) {
		min-height: 16rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a a'
			'b b';
	}
}

.images--3 {
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas:
		'a a a a b b'
		'a a a a c c';

	@media (max-width: variables.$breakpoint-lg) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'a a b b'
			'c c c c';
	}

	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a a'
			'b b'
			'c c';
	}
}

.images--4 {
	grid-template-columns: repeat(8, 1fr);
	grid-template-areas:
		'a a a a b b d d'
		'a a a a c c d d';

	@media (max-width: variables.$breakpoint-xl) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'a a b b'
			'c c d d';
	}

	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a a'
			'b b'
			'c c'
			'd d';
	}
}

.images--5 {
	grid-template-columns: repeat(12, 1fr);
	grid-template-areas:
		'a a a a a b b b b e e e'
		'a a a a a c c d d e e e';

	@media (max-width: variables.$breakpoint-xl) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'a a b b'
			'c c c c'
			'd d e e';
	}

	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a a'
			'b b'
			'c c'
			'd d'
			'e e';
	}
}

.item--1,
.item--2,
.item--3,
.item--4,
.item--5 {
	position: relative;
	min-height: 16rem;

	@media (max-width: variables.$breakpoint-lg) {
		min-height: var(--image-min-height-md);
	}

	@media (max-width: variables.$breakpoint-md) {
		min-height: var(--image-min-height-sm);
	}
}

.item--1 {
	grid-area: a;
}

.item--2 {
	grid-area: b;
}

.item--3 {
	grid-area: c;
}

.item--4 {
	grid-area: d;
}

.item--5 {
	grid-area: e;
}
