@use 'src/styles/variables' as variables;

.radiobuttonContainer.radiobuttonContainer.radiobuttonContainer {
	padding: 0 var(--space-stack-sm);
	font-size: 16px;

	[class*='Tooltip_tooltip'] {
		max-width: 14rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		[class*='Radiobutton_tooltipWrapper'] {
			display: none;
		}
	}
}
