.icon {
	position: relative;
	transform: rotate(0deg);
	transition: 0.3s ease-in-out;
	cursor: pointer;
	margin-right: var(--space-inline-sm);
	border: none;
	background: transparent;
	padding-right: 0.6875rem;
	padding-left: 0.6875rem;
	width: 1.3125rem;
	min-width: 1.3125rem;
	height: 1.5rem;

	&.open {
		transform: rotate(-90deg);
		transition: 0.3s ease-in-out;
	}
}

.color--light {
	.line {
		background: var(--text-light);
	}
}

.color--dark {
	.line {
		background: var(--text-dark);
	}
}

.line {
	display: block;
	position: absolute;
	left: 0;
	transform: rotate(0deg);
	opacity: 1;
	transition: all 0.3s ease-in-out;
	border-radius: 3.125rem;
	width: 100%;
	height: 2px;
}

.line1 {
	top: 3px;
}

.line2,
.line3 {
	top: 11px;
}

.line4 {
	top: 19px;
}

.open .line1 {
	top: 3px;
	left: 50%;
	width: 0%;
	height: 0%;
}

.open .line2 {
	transform: rotate(45deg);
}

.open .line3 {
	transform: rotate(-45deg);
}

.open .line4 {
	top: 3px;
	left: 50%;
	width: 0%;
	height: 0%;
}
