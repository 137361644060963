@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

$imageSize: functions.rem(330px);

@mixin styling($imageSize) {
	.image {
		width: $imageSize;
		min-width: $imageSize;
		height: $imageSize;
		min-height: $imageSize;
	}

	.backgroundFill {
		left: calc($imageSize / 2);
		height: $imageSize;
	}
}

@media (min-width: variables.$breakpoint-xl) {
	@include styling($imageSize: 20.625rem);
	.hasSubMenuV1 {
		@include styling($imageSize: 15rem);
	}
}

@media (max-width: variables.$breakpoint-xl) {
	@include styling($imageSize: 14rem);
}
@media (max-width: variables.$breakpoint-md) {
	@include styling($imageSize: 10.3125rem);
}

.brandLine {
	--brand-stripe-1-bg: var(--primary-yellow);
}

.titleWrapper {
	margin-bottom: var(--space-stack-lg);
}

.contentWrapper {
	display: flex;
	align-items: center;
	padding-left: var(--space-inset-xl);
}

.wrapper {
	display: flex;
	position: relative;
	align-items: center;
}

.backgroundFill {
	position: absolute;
	right: 0;
	border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;

	@media (max-width: variables.$breakpoint-md) {
		left: 0;
		border-radius: var(--border-radius-md);
		height: 100%;
	}
}

.image {
	position: relative;
	z-index: 1;
	border-radius: 50%;
	background: white;
	overflow: hidden;
}

.contactList {
	margin: var(--space-stack-lg) 0 0 0;
	padding: 0;
	list-style-type: none;

	.contactItem {
		display: flex;
		align-items: center;
		margin-bottom: var(--space-stack-md);
		line-height: 0;
		a {
			margin-right: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		.icon {
			margin-right: 1rem;
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.image {
		margin: 0 auto;
		margin-top: -3rem;
		margin-bottom: var(--space-stack-xl);
	}
	.wrapper {
		display: block;
		padding: var(--space-inset-md);
		height: auto;
		.contentWrapper {
			padding-left: 0;
			width: 100%;
		}
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.contentWrapper {
		padding-left: var(--space-inset-2xl);
	}

	.hasSubMenuV1 {
		.contentWrapper {
			padding-left: var(--space-inset-xl);
		}
	}
}

@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
	.contentWrapper {
		padding-left: var(--space-inset-md);
	}
}

@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
	.contentWrapper {
		padding-left: var(--space-inset-2xl);
	}
}

.border {
	border: 1px solid var(--line-color);
}
