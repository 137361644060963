@use 'src/styles/variables' as variables;

.container {
	border-radius: var(--border-radius-md);
	padding: var(--space-inset-2xl);
}

.header {
	margin-bottom: var(--space-stack-2xl);
}

.section {
	margin: var(--space-stack-2xl) 0;
}

.footer {
	margin-top: var(--space-stack-xl);
}

@media (max-width: variables.$breakpoint-sm) {
	.container {
		padding: var(--space-inset-lg);
		padding-top: var(--space-stack-xl);
	}
}
