@use 'src/styles/variables' as variables;

.container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);

	@media (min-width: variables.$breakpoint-lg) {
		grid-template-columns: repeat(3, 1fr);
	}
}
