@use 'src/styles/variables' as variables;

.bgColor {
	background-color: var(--bg-white);

	@media (max-width: variables.$menu-bp) {
		background-color: #f4faf8;
	}
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--bg-white);
	color: var(--primary-dark-green);

	&:hover {
		background: var(--primary-live-green);
		color: var(--text-light);
	}
}

.buttonOutline {
	border: 0.0625rem solid var(--border-color) !important;
	background: var(--bg-white);
	color: var(--text-dark);

	&:hover {
		background: transparent;
	}
}

.textLink {
	color: var(--text-dark);
	--link-hover-effect-color: var(--text-dark);
}
