@use 'src/styles/variables' as variables;

.linkButton {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-xl);
}

.header {
	padding: 0 var(--space-inline-xl);
}

.backgroundImage.backgroundImage {
	position: absolute;
	filter: grayscale(100%);

	div:first-child {
		max-width: 100%;
	}
}

.section {
	position: relative;
}

.containerFoldoutButton {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-xl);
	}
}
