@use 'src/styles/variables' as variables;
@use 'src/styles/functions' as functions;

.outerContainer {
	--brand-stripe-1-bg: var(--primary-yellow);
	position: relative;
}

.imageContainer {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
}

.gridContainer {
	height: 100%;
}

.alignCenter {
	display: flex;
	align-items: center;
}

.headerContainer {
	max-width: 80%;
}

.layoutCenter {
	.headerContainer {
		max-width: unset;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.innerContainer {
		min-height: auto;
	}
	.imageContainer {
		position: relative;
		width: 100%;
		height: functions.rem(300px);
	}
	.gridItem {
		position: relative;
	}
	.headerContainer {
		max-width: 100%;
	}
}
