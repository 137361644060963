@use 'src/styles/variables' as variables;

.container.container.container {
	@media (min-width: variables.$breakpoint-max) {
		margin: 8rem auto;
		padding: 0 8rem;
	}
}

.section {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	overflow: hidden;

	@media (max-width: variables.$breakpoint-lg) {
		justify-content: flex-start;
	}
}

.headerWrapper {
	position: absolute;
	z-index: var(--z-index-7);
	padding: var(--space-inset-3xl);
	max-width: 35rem;

	@media (max-width: variables.$breakpoint-lg) {
		position: unset;
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl) var(--space-inset-xl) var(--space-inset-xl);
	}
}

.desktopHeader {
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.mobileHeader {
	@media (min-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.accent-color--pink {
	.header {
		--brand-stripe-1-bg: var(--accent-pink);
	}
}

.videoWrapper {
	@media (max-width: variables.$breakpoint-lg) {
		position: relative;
		width: 100%;
	}
}

.overlay {
	position: absolute;
	z-index: var(--z-index-7);
	background: linear-gradient(to right, rgba(0, 56, 36, 0.75) 0%, rgba(0, 56, 36, 0) 80%);
	width: 100%;
	height: 100%;

	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}
