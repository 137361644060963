@use 'src/styles/variables' as variables;

.card.card.card {
	width: 33rem;
	height: auto;
	min-height: 10rem;
	overflow: hidden;

	@media (max-width: variables.$breakpoint-md) {
		width: fit-content;
		text-align: left;
	}
}

.cardContentGrid {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.image {
	position: relative;
	height: 15rem;

	@media (max-width: variables.$breakpoint-md) {
		height: 7.5rem;
	}
}

.cardImage {
	border-radius: var(--border-radius-md);
	height: 16rem;
}

.cardBody {
	padding: var(--space-inset-xl);

	p {
		white-space: pre-line;
	}
}

.uspContainer {
	display: block;
	margin-bottom: var(--space-inline-xl);

	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: auto;
	}

	div {
		display: flex;
	}
}

.uspContainer > div.usp {
	display: flex;
	grid-column: 1;
	@media (max-width: variables.$breakpoint-md) {
		grid-column: 1;
	}
}

.uspContainer > div.antiUsp {
	display: flex;
	grid-column: 2;
	@media (max-width: variables.$breakpoint-md) {
		grid-column: 1;
	}
}

.cardUspIcon.cardUspIcon.cardUspIcon {
	vertical-align: bottom;
	float: left;
	margin-right: var(--space-inline-md);
	@media (max-width: variables.$breakpoint-lg) {
		width: 1.425rem;
		height: 1.425rem;
	}
}

.cardFootnote.cardFootnote.cardFootnote {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	padding-right: var(--space-inset-xl);
	padding-bottom: var(--space-inset-xl);
}

.readMore {
	display: flex;
	justify-content: flex-end;
	font-size: var(--font-size-smal);
}
