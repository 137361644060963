@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.container {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: variables.$breakpoint-lg) {
		flex-direction: column;
		text-align: center;
	}
}

.layout--center {
	flex-direction: column;
	text-align: center;

	.icon {
		margin-bottom: var(--space-stack-md);
	}
}

.layout--left {
	.icon {
		margin-right: var(--space-inset-lg);
		@media (max-width: variables.$breakpoint-lg) {
			margin-right: 0;
			margin-bottom: var(--space-stack-md);
		}
	}
}

.text {
	animation: fadeIn 0.3s ease;
}
