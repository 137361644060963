.ctaWrapper {
	border-radius: var(--border-radius-md);
	background: var(--bg-dark-green-extra-light);
	padding: 0 var(--space-inset-xl) var(--space-inset-xl);
}

.imageWrapper {
	display: flex;
	justify-content: center;
	padding-top: var(--space-stack-xs);
	max-width: 100%;
}

.textWrapper {
	padding-top: var(--space-stack-xl);
}

@media (max-width: 48rem) {
	.textWrapper {
		padding-top: var(--space-stack-md);
	}
}
