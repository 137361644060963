@use 'src/styles/variables' as variables;

.headerContainer {
	display: flex;
	position: relative;
	justify-content: center;
	min-height: 45rem;

	@media (max-width: variables.$breakpoint-xl) and (min-width: variables.$breakpoint-lg) {
		height: 85vh;
	}

	@media (min-width: variables.$breakpoint-xl) {
		height: 100vh;
		max-height: 65rem;
	}

	@media (min-width: variables.$breakpoint-xl) {
		height: 80vh;
		max-height: 65rem;
	}

	@media (min-width: variables.$breakpoint-max) {
		min-height: 51rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		min-height: initial;
	}
}

.imageLeft,
.imageRight {
	position: relative;
	width: 50%;
}

.imageRight {
	@media (max-width: variables.$breakpoint-lg) {
		width: 100%;
		min-height: 26rem;
	}
}

.overlay {
	@media (max-width: variables.$breakpoint-lg) {
		position: absolute;
		inset: auto 0 0;
		background: linear-gradient(9deg, rgba(0, 0, 0, 0.41) 27%, rgba(0, 56, 36, 0) 65.5%);
		height: 17.25rem;
	}
}

.content {
	display: flex;
	position: absolute;
	flex-direction: column;
	align-items: center;
	text-align: center;

	@media (min-width: variables.$breakpoint-lg) {
		justify-content: center;
	}
}

.text {
	text-align: center;
}

.btnPosition {
	display: flex;
	justify-content: center;
	@media (max-width: variables.$breakpoint-lg) {
		margin-bottom: var(--space-stack-xl);
	}
}

.mobile {
	display: none;
	@media (max-width: variables.$breakpoint-lg) {
		display: block;
	}
	.content {
		bottom: 0;
	}
	.textWrapper {
		padding: var(--space-stack-xl);
	}

	@media (max-width: variables.$breakpoint-lg) {
		.title,
		.textWrapper {
			max-width: var(--text-max-width);
		}
		.textWrapper {
			margin: 0 auto;
		}
	}
}

.desktop {
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}

	.content {
		margin-bottom: 6rem;
		border-radius: 0 0 23rem;
		width: calc(100vw / 3);
		max-width: 40rem;
		height: 100%;

		@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-xl) {
			border-radius: 0 0 15rem;
			max-width: 45rem;
		}
	}

	.inner {
		display: flex;
		position: absolute;
		flex-direction: column;
		align-items: center;
		padding: 0 5rem;
		padding-bottom: var(--space-inset-4xl);
		text-align: center;

		@media (max-width: variables.$breakpoint-xl) {
			padding: 0 var(--space-inset-2xl);
			padding-bottom: var(--space-inset-4xl);
		}

		@media (max-width: variables.$breakpoint-xl) {
			padding: 0 var(--space-inset-xl);
		}
	}

	.menuVersionV3 {
		.inner {
			padding-top: 5rem;
		}
	}
}
