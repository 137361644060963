@use 'src/styles/variables' as variables;

.gridItemText {
	display: flex;
	align-items: center;
}

@media (min-width: variables.$breakpoint-lg) {
	.textContainer {
		width: 80%;
	}
}
