@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.content {
	position: relative;
	min-height: calc(100vh - var(--global-menu-height));
	text-align: center;

	.backgroundImage {
		div:first-child {
			max-width: 100%;
		}
		@media (max-width: variables.$breakpoint-md) {
			display: none;
		}
	}

	.showDesktopImage {
		display: block;
	}

	.mobileBackgroundImage {
		display: none;
		@media (max-width: variables.$breakpoint-md) {
			display: block;
		}
	}

	.contentContainer {
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: space-between;
		z-index: var(--z-index-1);
		padding: var(--space-stack-md);
		min-height: calc(100vh - var(--global-menu-height));

		@media (min-width: variables.$breakpoint-md) {
			padding: var(--space-stack-2xl) var(--space-stack-3xl) var(--space-inset-5xl) var(--space-stack-3xl);
		}

		@media (min-width: variables.$breakpoint-lg) {
			padding-top: calc(functions.rem(65px) + var(--space-stack-3xl));
			padding-bottom: var(--space-inset-4xl);
		}

		.brandLine {
			margin: 0 auto;
			margin-bottom: var(--space-stack-md);

			&.brandlineMargin {
				margin-bottom: var(--space-stack-xl);
			}
		}

		.headerText {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			text-align: center;

			@media (max-width: variables.$breakpoint-lg) {
				padding: var(--space-inset-4xl) 0 0;
			}
		}

		.text {
			@media (min-width: variables.$breakpoint-xl) {
				max-width: 70%;
			}
			@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-xl) {
				padding: 0 var(--space-inset-2xl);
			}
		}

		&::-webkit-scrollbar {
			display: none;
		}

		.gridContainer {
			display: flex;
			justify-content: center;
			margin: 0 auto;
			margin-bottom: var(--space-stack-3xl);
			@media (max-width: variables.$breakpoint-lg) {
				margin: 0 0 var(--space-inset-xl);
			}
			.card {
				height: auto;
				text-align: left;

				.cardImage {
					padding: 0;
					> div:first-child {
						@media (min-width: variables.$breakpoint-xl) {
							max-width: 22rem;
							height: 20rem;
						}
					}
					> div:nth-child(2) {
						@media (min-width: variables.$breakpoint-xl) {
							width: 22rem;
						}
						@media (min-width: variables.$breakpoint-lg) and (max-width: variables.$breakpoint-xl) {
							width: 16rem;
						}
					}
				}
			}
		}

		.linkToggle {
			margin: 0 auto;
			@media (min-width: variables.$breakpoint-lg) {
				margin-bottom: var(--space-stack-xl);
			}

			@media (max-width: variables.$breakpoint-md) {
				margin-bottom: var(--space-stack-2xl);
			}
			@media (max-width: variables.$breakpoint-sm) {
				margin: 0 0 var(--space-stack-2xl) 0;
			}
		}
	}
}
