@use 'src/styles/variables' as variables;

.card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.image {
	position: relative;
	min-height: 19rem;
}

.border,
.childTheme {
	.image {
		img {
			border-radius: 0.42rem 0.42rem 0 0;
		}
	}

	.textContainer {
		padding: var(--space-inset-xl);

		@media (max-width: variables.$breakpoint-md) {
			padding: var(--space-inset-lg);
		}
	}
}

.withImage {
	.image {
		img {
			border-radius: var(--border-radius-md);
		}
	}

	.textContainer {
		padding: var(--space-inset-xl) 0;
	}
}

.iconWrapper {
	display: flex;
	justify-content: center;
	margin: var(--space-stack-2xl) 0;
}

.image-height--1,
.image-height--2 {
	min-height: 19rem;
}

.image-height--3 {
	min-height: 15.625rem;
}

.image-height--4 {
	min-height: 13.75rem;
}

.image-height--1,
.image-height--2,
.image-height--3,
.image-height--4 {
	@media (max-width: variables.$breakpoint-lg) {
		min-height: 25rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		min-height: 15rem;
	}
}

.largeImage {
	.buttonContainer {
		align-items: flex-start;
	}

	.image {
		min-height: 22rem;

		@media (max-width: variables.$breakpoint-md) {
			min-height: 15.625rem;
		}
	}
}

.textContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.buttonContainer {
	display: flex;
	flex: 1;
	align-items: flex-end;
	margin-top: 1.5rem;
}
