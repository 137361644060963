.container {
	--brand-stripe-1-bg: var(--text-yellow);
	--body-color: var(--text-dark);
}

.smallTitle {
	--title-color: var(--primary-green);
}

.title {
	margin: var(--space-stack-xl) 0;
}

.brandLine {
	margin-bottom: var(--space-stack-lg);
}

.adjustableTextWidth {
	max-width: var(--text-max-width);
}
