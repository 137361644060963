.container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--space-stack-md);
	margin-bottom: var(--space-stack-md);
	padding: var(--space-inset-xl);

	div:last-child {
		grid-column: span 2;
	}

	@media (min-width: 36rem) {
		grid-template-columns: repeat(4, 1fr);
		div:first-child {
			grid-column-start: 1;
		}

		div:last-child {
			grid-column: span 2;
		}
	}
	@media (min-width: 48rem) {
		margin-bottom: var(--space-inset-xl);
		padding: 0;
	}

	@media (min-width: 62rem) {
		grid-template-columns: repeat(4, 1fr);

		div:first-child {
			grid-column: 1 / 2;
		}

		div:last-child {
			grid-column-start: 4;
		}
	}
}
