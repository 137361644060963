.form {
	width: 100%;
}

.headingContainer {
	padding-top: 1rem;
}

.licenseHeader {
	width: 100%;
}
.numberOfLicensesInfoContainer {
	margin-top: var(--space-stack-lg);
	width: 100%;
}

.orgNrField {
	width: 50%;
}

.companyInformationErrorMsg {
	--body-color: var(--red-400);
	margin-bottom: 0;
}

.companyInfoBtnContainer {
	align-self: baseline;
	margin-top: 0.75rem;
	margin-left: 2rem;
}

.companyInfoBtn {
	margin-left: var(--space-inset-lg);
	border: none;
	background: none;
	padding: 0;

	&:focus {
		background: none;
	}

	&:disabled {
		background: none;
		color: var(--disabled-color);
	}
}

.consentText {
	max-width: 25rem;
}

.integrityMsgBtn {
	display: inline;
	padding-right: 0;
	font-size: 0.875rem;
	line-height: 1.3125rem;
}

.selectCompanyType {
	width: 100%;
}

.textFieldContainer {
	width: 100%;
}

.orgNrContainer {
	display: flex;
}

@media (max-width: 36rem) {
	.licensesContainer {
		flex-direction: column;
	}
	.selectNumberOfLicenses {
		padding-bottom: 0;
		width: 100%;
	}
	.numberOfLicensesInfoContainer {
		margin: 0;
		margin-bottom: 0;
	}

	.orgNrContainer {
		flex-direction: column;
	}
	.orgNrField {
		padding-bottom: 0;
		width: 100%;
	}

	.companyInfoBtnContainer {
		margin: 0;
		margin-bottom: 1rem;
	}

	.companyInformationErrorMsg {
		margin-bottom: initial;
	}
}
