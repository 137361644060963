@use 'src/styles/variables' as variables;

.grid {
	column-gap: var(--space-inset-2xl);
	row-gap: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-lg) {
		column-gap: var(--space-inset-5xl);
		margin: 0 auto;
		max-width: 38rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		column-gap: var(--space-inset-md);
		row-gap: var(--space-stack-xl);
	}
}
