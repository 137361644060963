@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.container {
	display: flex;
	position: sticky;
	top: var(--global-menu-height);
	justify-content: center;
	align-items: center;
	z-index: var(--z-index-10);
	transition:
		top 0.3s ease-in-out,
		box-shadow 0.3s ease-in-out;
	margin: 0 auto;
	background-color: var(--bg-white);
	width: 100%;
	max-width: 1922px;

	height: var(--global-menu-height);

	&.boxShadow {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
	}

	&.zIndex {
		z-index: var(--z-index-7);
	}

	&.menuVersionV3 {
		top: calc(var(--global-menu-height) + var(--global-menu-offset) * 2);
		right: calc(var(--global-menu-offset + 1px));
		left: calc(var(--global-menu-offset) - 1px);
		border-radius: var(--border-radius-menu);
		padding: var(--space-inset-lg) var(--space-inset-xl);
		width: calc(100vw - var(--global-menu-offset) * 2 - var(--scrollbar-width) + 2px);
		min-height: calc(var(--global-menu-height) + 1px);
	}

	&.hideGlobalMenu {
		top: var(--global-menu-offset);
	}
}

.subMenuDesktop {
	display: flex;
	justify-content: space-between;
}

.item {
	position: relative;
	cursor: pointer;

	&.openLinkGroup,
	&:hover {
		.linkGroup,
		.mainItemWrapper {
			transition: background-color 0.5s ease-in-out;
			background-color: var(--sub-menu-active);
		}

		.mainItemWrapper {
			border-radius: var(--border-radius-md);

			&.hasSubItems {
				border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
			}
		}

		.icon {
			rotate: 180deg;
		}

		.linkGroup {
			display: flex;
			position: absolute;
			flex-direction: column;
			gap: 0.25rem;
			z-index: 5000;
			animation: fadeIn 0.5s ease-in-out;
			box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.05);
			border-radius: 0 0.5rem 0.5rem;
			padding: var(--space-inset-md);
			width: max-content;
			min-width: 120%;
		}
	}
}

.icon {
	width: 0.75rem;
	height: 0.75rem;
	font-size: 0.75rem;
}

.linkGroup {
	display: none;
}

.mainItemWrapper {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: var(--space-inset-xs) var(--space-inset-md);
}

.active.active.active {
	font-weight: 600;
	letter-spacing: -0.25px;
}
