@use 'src/styles/variables' as variables;

.brandline {
	--brand-stripe-1-bg: var(--action-hovered-color);
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	margin-bottom: 8rem;
	max-width: 60rem;
	text-align: center;

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 50rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: var(--space-stack-3xl);
	}
}

.text {
	@media (min-width: variables.$breakpoint-lg) {
		padding: 0 6rem;
	}

	@media (min-width: variables.$breakpoint-xl) {
		padding: 0 8rem;
	}
}
