@use 'src/styles/variables' as variables;

.container {
	--search-widget-inner-width: 48.75rem;
	--inner-offset: calc((100vw - var(--global-menu-offset) * 2) * 0.1);
	display: inline-flex;
	flex-direction: column;
	transform: translateX(-100%);
	opacity: 0;
	z-index: var(--z-index-max);
	border-top-right-radius: 7.4375rem;
	background-color: #f4faf8;
	padding: var(--space-inset-xl) var(--space-inset-4xl);
	height: 0;

	&.open,
	&.slideIn {
		transform: translateX(0);
		opacity: 1;
		height: 100%;

		.searchResult {
			height: auto;
			min-height: 41rem;
			overflow: initial;

			@media (max-width: variables.$menu-bp) {
				min-height: 105rem;
			}
		}
	}

	&.slideIn {
		animation: slideIn 0.8s ease-in-out;
	}

	&.slideOut {
		animation: slideOut 0.4s ease-in-out;
	}

	@media (max-width: variables.$menu-bp) {
		position: absolute;
		opacity: 0;
		border-top-right-radius: 0;
		padding: var(--space-inset-md) var(--space-inset-lg);
		height: 0;

		&.open,
		&.slideIn {
			opacity: 1;
			inset: 0;
			height: 100%;
		}

		&.slideIn {
			animation: slideInMobile 0.4s ease-in-out;
		}

		&.slideOut {
			animation: slideOutMobile 0.4s ease-in-out;
		}
	}
}

.mobile {
	display: none;

	@media (max-width: variables.$menu-bp) {
		display: flex;
	}
}

.desktop {
	@media (max-width: variables.$menu-bp) {
		display: none;
	}
}

.text,
.closeBtnWrapper,
.searchResult {
	margin: 0 auto;
	padding-left: var(--inner-offset);
	width: 100%;
	max-width: calc(var(--search-widget-inner-width) + var(--inner-offset));

	@media (max-width: variables.$menu-bp) {
		padding-left: unset;
	}
}

.text {
	text-align: center;
}

.closeBtnWrapper {
	margin-bottom: var(--space-stack-xl);

	@media (max-width: variables.$menu-bp) {
		margin-bottom: var(--space-stack-sm);
	}
}

.closeBtnInner {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	min-width: 7rem;
	max-width: var(--search-widget-inner-width);

	@media (max-width: variables.$menu-bp) {
		min-width: 0;
	}
}

.searchResult {
	margin: 0 auto;
	background-color: #f4faf8;
	width: 100%;
	height: 0;
	min-height: 0;
	overflow: hidden;

	@media (max-width: variables.$menu-bp) {
		max-width: unset;
	}
}

@keyframes slideIn {
	0% {
		transform: translateX(-80%);
		opacity: 0;
		height: 0;
	}

	30% {
		opacity: 0;
		height: 100%;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideOut {
	0% {
		transform: translateX(0);
		opacity: 1;
		height: 100%;
	}

	20% {
		opacity: 1;
		height: 100%;
	}

	100% {
		transform: translateX(-80%);
		opacity: 0;
	}
}

@keyframes slideInMobile {
	0% {
		transform: translateX(-100%);
		height: 100%;
	}

	100% {
		transform: translateX(0);
		inset: 0;
	}
}

@keyframes slideOutMobile {
	0% {
		transform: translateX(0);
		opacity: 1;
		inset: 0;
		height: 100%;
	}

	99% {
		opacity: 1;
		inset: 0;
		height: 100%;
	}

	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}
