@use 'src/styles/variables' as variables;

.facebook {
	height: 1rem;
}

.container {
	display: flex;
	position: relative;
	gap: 1rem;
	&.background {
		gap: 0.5rem;
	}
}

.socialMediaItem {
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

.background {
	.socialMediaItem {
		z-index: 0;
		border-radius: 10rem;
		background: var(--bg-light-grey);
		width: 2.5rem;
		height: 2.5rem;
	}
}

.copied {
	animation: vibe 1s ease;
}

@keyframes vibe {
	0% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
