.card {
	background-color: var(--support-light) !important;
}

.bgColor {
	background-color: var(--support-light) !important;
}

.titleColor {
	--title-color: var(--support-full);
	color: var(--support-full);
}

.textColor {
	color: var(--primary-dark-green) !important;
}

.backgroundPattern {
	background: url('/patterns/fortnox-pattern.svg');
	background-size: contain;
	background-color: var(--bg-dark-green-full);
}

.borderColor {
	--themed-border-color: var(--text-light);
	border-color: var(--themed-border-color);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--bg-white);
	color: var(--text-dark);

	&:hover {
		background: var(--support-full);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--text-light);
	background: transparent;
	color: var(--text-light);

	&:hover {
		border-color: var(--text-light) !important;
		background: var(--bg-white);
		color: var(--text-dark);
	}
}

.textLink {
	--link-hover-effect-color: var(--support-full);
	color: var(--support-full);

	&:hover {
		color: var(--support-full);
	}
}
