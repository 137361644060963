.button {
	display: flex;
	flex-shrink: 0;
	border: 0;
	background: none;
	padding: 0;
}

.icon {
	align-self: baseline;
}
