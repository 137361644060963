@use 'src/styles/variables' as variables;

.container.container.container {
	margin: var(--article-component-margin);
	border-radius: var(--border-radius-md);
	background: var(--bg-dark-green-extra-light);

	.headerWrapper {
		display: flex;
		align-items: center;
		margin-bottom: var(--space-stack-lg);

		.icon {
			margin-right: 1rem;
		}
	}

	.linkWrapper {
		margin-top: var(--space-stack-lg);
	}

	.layoutTwoColumns {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;

		.linkWrapper {
			margin-top: 0;
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		.layoutTwoColumns {
			.linkWrapper {
				margin-top: var(--space-stack-xl);
				width: 100%;
				.btn {
					width: 100%;
				}
			}
		}
	}
}
