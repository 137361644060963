.shortCuts {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: var(--space-stack-3xl);
	margin: 0 auto;
	margin-top: var(--space-stack-2xl);
	width: fit-content;
}

.shortCut {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--space-stack-lg);
}

.mutedTextSection {
	display: flex;
	justify-content: center;
	column-span: all;
	margin-bottom: var(--space-stack-xl);
	color: var(--custom-text-placeholder);
}
