.container {
	display: flex;
	flex-direction: column;
}

.amount {
	display: flex;
	flex-direction: column;
	margin-right: var(--space-inline-xs);
	padding-bottom: 0;
	font-weight: 600;
	line-height: 1;
}

.crossedOutPrice {
	font-weight: 400;
	text-decoration: line-through;
}

.from {
	font-size: var(--font-size-h6);
	text-align: left;
}

.priceLarge {
	line-height: 3rem;
}

.priceContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.price {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 11.25rem;
}

.priceText {
	align-self: flex-end;
	line-height: 1;
	white-space: nowrap;
}
