@use 'src/styles/variables' as variables;

.productBoxContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.productBoxHeaderWrapper {
	display: flex;
	align-items: center;
	margin-bottom: var(--space-stack-md);
}

.iconWrapper {
	display: flex;
	align-items: center;
	margin-right: var(--space-inline-xs);
}

.productBoxContent {
	display: flex;
	flex: 1;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: 6rem;
}

.buttonContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem 1.5rem;
}

.footer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
}

.priceBox {
	margin-bottom: var(--space-stack-md);
}

@media (min-width: variables.$breakpoint-lg) {
	.productBoxContainer {
		.buttonContainer {
			align-self: flex-start;
			width: 100%;
		}
	}
}
