.content {
	margin: 0 auto;
	width: 100%;
	max-width: 60rem;
}

.layout--two-columns {
	max-width: initial;
}

.grid {
	column-gap: 7rem;
	margin-bottom: var(--space-stack-lg);
}
