@use 'src/styles/variables' as variables;

.container.container.container {
	@media (min-width: variables.$breakpoint-xl) {
		margin-top: 5rem;
	}

	&.hasSubMenuV1 {
		margin-top: 0;

		@media (min-width: variables.$breakpoint-xl) {
			padding-top: 0;
		}

		@media (max-width: variables.$breakpoint-xl) {
			margin-top: 5rem;
		}
	}
}

.inner {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 75rem;

	@media (min-width: variables.$breakpoint-md) {
		align-items: center;
	}
}

.title {
	@media (min-width: variables.$breakpoint-md) {
		text-align: center;
	}
}

.gridContainer.gridContainer.gridContainer {
	height: auto;
}

.centeredText {
	width: 100%;

	div {
		max-width: 45rem;
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flex;
		justify-content: center;
		text-align: center;
	}
}

.imageContainer {
	position: relative;
	width: 100%;
	min-height: 20rem;
}
