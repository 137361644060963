.bgColor {
	background-color: var(--bg-white);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}

.textLink {
	--link-hover-effect-color: var(--text-dark);
	color: var(--text-dark);

	&:hover {
		color: var(--text-dark);
	}
}

.iconColor {
	--icon-color: var(--text-dark);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background-color: var(--bg-white);
	color: var(--text-dark);

	&:hover {
		background-color: var(--bg-white);
		color: var(--text-dark);
	}
}
