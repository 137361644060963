@use 'src/styles/variables' as variables;

.innerContainer {
	display: flex;

	&.hasSubMenu {
		justify-content: center;
		margin: 0 auto;
		margin-bottom: var(--space-stack-3xl);
		max-width: var(--global-max-width);
	}

	&.hasSubMenuV1 {
		margin-top: 2rem;
		padding: var(--space-inset-3xl) var(--space-inset-3xl) 0 var(--space-inset-3xl);
	}

	&.hasSubMenuV2 {
		padding: 0 var(--space-inset-3xl);
	}

	&.menuVersionV3 {
		&.hasSubMenuV1 {
			margin-top: 5rem;

			@media (max-width: variables.$breakpoint-xl) {
				margin-top: 0;
			}
		}
	}
}

.contentColumn {
	width: 100%;
}

.subMenuColumn {
	margin-right: var(--space-inline-2xl);
	width: 20%;
	min-width: 20%;
}

.cardContainer {
	margin-bottom: var(--space-stack-lg);
}

.subMenu {
	position: sticky;
	top: 7.5rem;
	transition: all ease 0.3s;
}

.onScroll {
	top: 7rem;
}

@media (max-width: variables.$breakpoint-max) {
	.innerContainer {
		&.hasSubMenuV1 {
			padding: var(--space-inset-3xl) var(--space-inset-lg) 0 var(--space-inset-lg);
		}

		&.hasSubMenuV2 {
			padding: 0 var(--space-inset-lg);
		}
	}

	.subMenuColumn {
		margin-right: var(--space-inline-xl);
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		top: var(--global-menu-height);
	}

	.subMenuColumn {
		display: none;
	}

	.innerContainer {
		flex-direction: column;

		&.hasSubMenu {
			margin-top: 0;
			padding: 0;
		}
	}

	.mobileMenu {
		position: fixed;
	}
}

@media (min-width: variables.$breakpoint-lg) {
	.mobileMenu {
		display: none;
	}
}

@media (min-width: variables.$breakpoint-lg) {
	.subMenuColumn {
		width: 30%;
	}
}
