.icon {
	align-self: baseline;
	height: 1.5rem;
	font-size: 1.5rem;
}

.icon--facebook-f {
	height: 1.35rem;
	font-size: 1.35rem;
}
