.hoverEffect {
	display: inline-block;

	a {
		display: inline;
		border-bottom: 2px solid transparent;

		&:hover,
		&:active,
		&:focus {
			transition: all 0.15s ease-in-out;
			outline: none;
			border-bottom-color: var(--link-hover-effect-color);
			padding-bottom: 2px;
		}
	}
}
