@use 'src/styles/variables' as variables;

.container {
	max-width: 44rem;

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inline-md);
	}
}

.tagline {
	margin-bottom: var(--space-stack-xl);
	color: var(--primary-green);
}

.accordionContent {
	display: flex;
	justify-content: center;
}
