@use 'src/styles/variables' as variables;

.card {
	background-color: var(--primary-live-green);
}

.bgColor {
	background-color: var(--primary-live-green);
}

.titleColor {
	--title-color: var(--text-heading-color);
	color: var(--title-color);
}

.textColor {
	--body-color: var(--text-dark);
	color: var(--body-color);
}

.smallTitleColor {
	color: var(--text-dark);
}

.accentTextColor {
	color: var(--text-light);
}

.brandlineColor {
	--brand-stripe-1-bg: var(--bg-white);
}

.uspIconColor,
.iconColor,
.antiIconColor {
	color: var(--icon-color);
	--icon-color: var(--text-dark);
}

.backgroundPattern {
	background: url('/patterns/fortnox-pattern-live-green.svg');
	background-size: contain;
	background-color: var(--primary-live-green);
}

.borderColor {
	--themed-border-color: var(--bg-white);
	border-color: var(--themed-border-color);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--bg-white);
	color: var(--text-dark);

	&:hover {
		background: var(--bg-dark-green-full);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--text-light);
	background: transparent;
	color: var(--text-light);

	&:hover {
		border-color: var(--bg-dark-green-full) !important;
		background: transparent;
		color: var(--text-dark);
	}
}

.textLink {
	--link-hover-effect-color: var(--text-dark);
	color: var(--text-dark);

	&:hover {
		color: var(--text-dark);
	}
}
// LinkToggle
.linkToggleActive.linkToggleActive {
	background: var(--primary-green);
	color: var(--text-light);
}
.linkToggleInactive.linkToggleInactive {
	color: var(--text-dark);

	&:hover {
		color: var(--text-dark);
	}
}
.linkToggleBgColor {
	background-color: var(--bg-white);
}

// Carousel indicators
.carouselIndicatorColor {
	--indicator-color: var(--text-light);
}
