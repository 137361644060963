.container {
	overflow: hidden;
}

.absolute {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.cover {
	width: 100%;
	height: 100%;
}
