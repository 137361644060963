@use 'src/styles/variables' as variables;

.container {
	--logo-width: 184px;
	--logo-height: 54px;
	--logo-margin: 2rem;
	--nr-of-visible-logos: 5;

	@media (max-width: variables.$breakpoint-md) {
		--logo-width: 140px;
		--logo-height: 41px;
	}
}

.logoGridContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4rem;
	margin-bottom: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		gap: 2rem 4rem;
	}
}

.buttonContainer {
	display: flex;
	justify-content: center;
}

.button.button.button {
	margin-right: 0;
}

.grayscaleLogo {
	filter: grayscale(1);
}

.logo {
	position: relative;
	min-width: var(--logo-width);
	max-width: var(--logo-width);
	min-height: var(--logo-height);
	max-height: var(--logo-height);
}

// ANIMATION
.carusel {
	position: relative;
	margin: 0 auto;
	overflow: hidden;

	@media (min-width: variables.$breakpoint-max) {
		&::before,
		&::after {
			position: absolute;
			z-index: 2;
			background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			width: 2rem;
			height: 100%;
			content: '';
		}

		&::after {
			top: 0;
			right: 0;
			transform: rotateZ(180deg);
		}

		&::before {
			top: 0;
			left: 0;
		}
	}
}

.caruselInner {
	display: flex;
	animation: scroll var(--duration-time) linear infinite;
	width: calc((var(--logo-width) + (var(--logo-margin) * 2)) * var(--nr-of-visible-logos));
}

.caruselItem {
	margin: 0 var(--logo-margin);
	span {
		display: block;
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc((var(--logo-width) + (var(--logo-margin) * 2)) * var(--nr-of-logos) * -1));
	}
}
