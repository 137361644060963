@use 'src/styles/variables' as variables;

.container {
	padding-top: var(--space-inset-xl);
	text-align: center;

	&.menuVersionV3 {
		padding-top: var(--space-inset-7xl);

		@media (max-width: variables.$menu-bp) {
			padding-top: var(--space-inset-5xl);
		}
	}

	&.hasSubMenuV2 {
		@media (max-width: variables.$menu-bp) {
			padding-top: var(--space-inset-5xl);
		}
	}
}
