@use 'src/styles/variables' as variables;

.offertaModal.offertaModal {
	position: fixed;
	margin-top: var(--space-inset-5xl);
	padding: var(--space-inset-3xl) var(--space-inset-md) var(--space-inset-md) var(--space-inset-md);
	max-width: 90%;
	text-align: left;

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-inset-4xl);
	}

	.formWrapper {
		max-height: 65vh;
		overflow-y: auto;
	}

	.image {
		display: flex;
		position: absolute;
		top: var(--space-stack-md);
		align-items: center;
		gap: var(--space-inline-xs);
		margin-bottom: var(--space-stack-md);
		.text {
			font-size: var(--font-size-small);
		}
	}
}

.offertaButton {
	margin-top: var(--space-stack-xl);
	width: 100%;
}
