@use 'src/styles/variables' as variables;

.title {
	display: flex;
	margin-bottom: var(--space-stack-xl);
	white-space: nowrap;
}

.grid {
	height: 168px;
}

.logos {
	flex-grow: 2;
}

.logo {
	position: relative;
	width: 128px;
}

.gridItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 68px;
}

.container.container.container {
	min-height: 100vh;
	overflow-x: hidden;

	&:not(.menuVersionV3) {
		min-height: calc(100vh - var(--global-menu-height));
	}
}

.menuVersionV3 {
	.headerCommon {
		margin-top: 3rem;
	}
}

.contentBlock {
	display: flex;
	flex-direction: column;
	height: 280px;
}

.titlePart1.titlePart1 {
	font-weight: 700;
}

.titlePart2.titlePart2 {
	font-weight: 400;
}

.logosContainer {
	display: flex;
	margin-top: 0;
}
.centerContent {
	@media (max-width: variables.$breakpoint-sm) {
		justify-content: center;
	}
}

.contentBlock:first-child {
	margin-left: calc(50% - 256px);

	@media (max-width: variables.$breakpoint-md) {
		margin-left: 0;
	}
}

.contentBlock:not(:last-child) {
	border-right: 1px solid var(--bg-mint-green-full);
	padding-right: var(--space-inset-5xl);
}

.contentBlock:not(:first-child) {
	padding-left: var(--space-inset-5xl);
}

.linkButton {
	display: flex;
	justify-content: flex-end;
}

.linkButtonMobile {
	@media (max-width: variables.$breakpoint-sm) {
		margin-top: var(--space-inset-5xl);
	}
}
