@use 'src/styles/variables' as variables;

.container {
	position: relative;
	padding-bottom: var(--space-stack-xs);
}

.sticker {
	display: flex;
	position: absolute;
	top: -2.7rem;
	right: 0;
	justify-content: center;
	align-items: center;
	border-radius: var(--border-radius-md);
	padding: var(--space-stack-md);
	height: 2.3rem;

	.circle {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		content: '';

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
	@media (max-width: variables.$breakpoint-xl) {
		top: -2.3rem;
		padding: var(--space-stack-xs);
		height: 1.6rem;
		.circle {
			width: 2rem;
			height: 2rem;
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	@media (max-width: variables.$breakpoint-sm) {
		.circle {
			width: 2rem;
			height: 2rem;
			svg {
				width: 1rem;
				height: 1rem;
			}
		}

		div {
			font-size: 12px;
		}
	}
}

.stickerWithIcon {
	border-radius: var(--border-radius-3xl) var(--border-radius-md) var(--border-radius-md) var(--border-radius-3xl);
	padding-left: 0;
}

.heading {
	color: var(--text-dark);
	font-weight: 700;
	font-size: var(--font-size-h6);
}
