.frcContainer {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: var(--space-stack-sm);
	width: 100%;
	font-size: 0.7rem;

	div {
		margin: 0;
		overflow: hidden;
	}

	span[class^='frc-banner'] {
		position: relative;
		width: 100%;
		text-align: right;
	}

	div[class^='frc-container'] {
		min-height: auto;
	}

	svg {
		margin: 0;
		margin-right: 0.25rem;
		width: 1rem;
	}

	button {
		background: none;
		padding: 0;
		color: var(--primary-green);
	}
}
