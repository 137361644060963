.container {
	margin-top: var(--space-inset-3xl);
	margin-bottom: var(--space-inset-3xl);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-xl);
	width: 100%;
}

.icon {
	padding: 0 0.2rem 0 0;
	width: auto;
	height: 20px;

	&:last-of-type {
		margin-right: 0;
	}
}
