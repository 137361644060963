@use 'src/styles/variables' as variables;

$card-height: 18rem;

.container {
	border-radius: var(--border-radius-md);
	padding: var(--space-inset-xl) var(--space-inset-3xl);
	height: $card-height;
}

.inner {
	height: calc($card-height - 4rem);
	overflow: hidden;
}

.linksColumn {
	overflow-x: hidden;
	overflow-y: auto;
}

.theme--dark-green {
	background: var(--bg-dark-green-full);
	color: white;

	.title {
		--title-color: var(--primary-yellow);
	}

	.smallTitle {
		--title-color: var(--text-light);
	}

	.text {
		--body-color: var(--text-light);
	}
}

.grid {
	@media (min-width: variables.$breakpoint-lg) {
		max-height: calc($card-height / 2);
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		height: auto;
	}

	.inner {
		height: auto;
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.contentWrapper {
		max-width: 70%;
	}
}

@media (max-width: variables.$breakpoint-sm) {
	.container {
		padding: var(--space-stack-xl) var(--space-inset-lg);
	}
}
