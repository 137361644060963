.bgColor {
	background-color: var(--bg-dark-green-full);
}

.iconColor {
	color: var(--icon-color);
	--icon-color: var(--text-light);
}

/* BUTTONS AND LINKS */
.buttonFilled {
	background: var(--bg-white);
	color: var(--text-dark);

	&:hover {
		background: var(--primary-live-green);
		color: var(--text-light);
	}
}

.buttonOutline {
	border-color: var(--text-light);
	background: transparent;
	color: var(--text-light);

	&:hover {
		border-color: var(--primary-live-green) !important;
		background: transparent;
		color: var(--primary-live-green);
	}
}

.textLink {
	color: var(--text-light);
	--link-hover-effect-color: var(--text-light);
}
