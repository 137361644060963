.appCardDecoration {
	--card-decoration-top-position: -0.625rem;
	--card-decoration-right-position: 1.5rem;
	--card-decoration-font-size: 0.656rem;
	--card-decoration-lineheight: 0.984rem;
	--card-decoration-border-radius: 0.625rem;
	--card-decoration-padding: 0.125rem 0.5rem;
	--card-decoration-height: 1.25rem;
	--card-decoration-max-width: 15.625rem;

	display: flex;
	position: absolute;
	top: var(--card-decoration-top-position);
	right: var(--card-decoration-right-position);

	align-items: center;
	border: 0.063rem solid var(--primary-yellow);
	border-radius: var(--card-decoration-border-radius);
	background-color: var(--primary-yellow);
	padding: var(--card-decoration-padding);
	width: fit-content;
	max-width: var(--card-decoration-max-width);
	height: var(--card-decoration-height);

	span {
		overflow: hidden;
		font-size: var(--card-decoration-font-size);
		line-height: var(--card-decoration-lineheight);
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
