@use 'src/styles/variables' as variables;

.grid {
	column-gap: 8rem;
}

.image {
	position: relative;
	min-height: 100%;

	img {
		object-position: center;
	}

	@media (max-width: variables.$breakpoint-xl) {
		min-height: 20rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		min-height: 24rem;
	}

	@media (max-width: variables.$breakpoint-sm) {
		min-height: 16rem;
	}
}

.layout--image-right {
	@media (min-width: variables.$breakpoint-lg) {
		.textColumn {
			order: 1;
		}

		.imageColumn {
			order: 2;
		}
	}
}
