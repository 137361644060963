@use 'src/styles/variables' as variables;

.link {
	font-weight: bold;
	text-decoration: none;
}

.link > * {
	vertical-align: middle;
}

.icon,
.external {
	--icon-color: var(--text-dark);
	padding-bottom: 2px;
	color: var(--icon-color);
}

.icon {
	margin-right: var(--space-inline-xs);
}

.external {
	vertical-align: text-bottom;
	margin-left: var(--space-inline-xs);
	width: 0.85rem;
	min-width: 0.85rem;
	font-size: 0.85rem;
}

.item {
	margin-bottom: var(--space-stack-lg);
	width: 100%;
	max-width: 25rem;
}

@media (max-width: variables.$breakpoint-md) {
	.item {
		max-width: 25rem;
	}
}
