@use 'src/styles/variables' as variables;

.containerFoldoutButton {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-xl);
	}
}

.foldoutButtonCardLarge {
	@media (max-width: variables.$breakpoint-lg) {
		padding-bottom: var(--space-inset-4xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding-bottom: var(--space-inset-3xl);
	}
}

.foldoutButtonCardLargeInverted {
	@media (min-width: variables.$breakpoint-lg) {
		padding-bottom: var(--space-inset-5xl);
	}
}

.padding {
	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-xl);
	}
}
