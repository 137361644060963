@use 'src/styles/variables' as variables;

.logo {
	width: var(--desktop-logo-width);
	height: var(--desktop-logo-height);

	@media (max-width: variables.$breakpoint-md) {
		width: var(--mobile-logo-width);
		height: var(--mobile-logo-height);
	}
}
