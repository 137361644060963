/* stylelint-disable declaration-block-no-redundant-longhand-properties */
@use 'src/styles/variables' as variables;

.container {
	$rowgaps: (
		'rowGap-3xs': var(--space-inline-3xs),
		'rowGap-2xs': var(--space-inline-2xs),
		'rowGap-xs': var(--space-inline-xs),
		'rowGap-sm': var(--space-inline-sm),
		'rowGap-md': var(--space-inline-md),
		'rowGap-lg': var(--space-inline-lg),
		'rowGap-xl': var(--space-inline-xl),
		'rowGap-2xl': var(--space-inline-2xl),
		'rowGap-3xl': var(--space-inline-3xl),
		'rowGap-4xl': var(--space-inline-4xl),
		'rowGap-5xl': var(--space-inline-5xl),
	);

	$colgaps: (
		'colGap-3xs': var(--space-inline-3xs),
		'colGap-2xs': var(--space-inline-2xs),
		'colGap-xs': var(--space-inline-xs),
		'colGap-sm': var(--space-inline-sm),
		'colGap-md': var(--space-inline-md),
		'colGap-lg': var(--space-inline-lg),
		'colGap-xl': var(--space-inline-xl),
		'colGap-2xl': var(--space-inline-2xl),
		'colGap-3xl': var(--space-inline-3xl),
		'colGap-4xl': var(--space-inline-4xl),
		'colGap-5xl': var(--space-inline-5xl),
	);

	$current: var(--grid-cols-base);
	$sizes: (
		'xs': variables.$breakpoint-sm,
		'sm': variables.$breakpoint-sm,
		'md': variables.$breakpoint-md,
		'lg': variables.$breakpoint-lg,
		'xl': variables.$breakpoint-xl,
		'xxl': variables.$breakpoint-xl,
	);
	display: grid;
	grid-template-rows: var(--grid-rows-base);
	grid-template-columns: var(--grid-cols-base);
	width: 100%;
	height: 100%;

	&__maxVHeight {
		height: 100vh;
	}

	&__maxVWidth {
		width: 100vw;
	}

	@each $gap, $rowGap in $rowgaps {
		&__#{$gap} {
			row-gap: $rowGap;
		}
	}

	@each $gap, $colGap in $colgaps {
		&__#{$gap} {
			column-gap: $colGap;
		}
	}
	@each $size, $minWidth in $sizes {
		$current: var(--grid-cols-#{$size}, $current);

		@media (min-width: $minWidth) {
			grid-template-columns: $current;
		}
	}
}

.item {
	$sizes: (
		'min': variables.$breakpoint-min,
		'sm': variables.$breakpoint-sm,
		'md': variables.$breakpoint-md,
		'lg': variables.$breakpoint-lg,
		'xl': variables.$breakpoint-xl,
		'max': variables.$breakpoint-max,
	);

	$colend: var(--griditem-colend-base);
	$colstart: var(--griditem-colstart-base);
	$rowend: var(--griditem-rowend-base);
	$rowstart: var(--griditem-rowstart-base);

	$colStarts: (
		'colStart-1': 1,
		'colStart-2': 2,
		'colStart-3': 3,
		'colStart-4': 4,
		'colStart-5': 5,
		'colStart-6': 6,
		'colStart-7': 7,
		'colStart-8': 8,
		'colStart-9': 9,
		'colStart-10': 10,
		'colStart-11': 11,
		'colStart-12': 12,
	);

	$colEnds: (
		'colEnd-1': 1,
		'colEnd-2': 2,
		'colEnd-3': 3,
		'colEnd-4': 4,
		'colEnd-5': 5,
		'colEnd-6': 6,
		'colEnd-7': 7,
		'colEnd-8': 8,
		'colEnd-9': 9,
		'colEnd-10': 10,
		'colEnd-11': 11,
		'colEnd-12': 12,
		'colEnd-13': 13,
	);

	$rowStarts: (
		'rowStart-1': 1,
		'rowStart-2': 2,
		'rowStart-3': 3,
		'rowStart-4': 4,
		'rowStart-5': 5,
		'rowStart-6': 6,
		'rowStart-7': 7,
		'rowStart-8': 8,
		'rowStart-9': 9,
		'rowStart-10': 10,
		'rowStart-11': 11,
		'rowStart-12': 12,
	);

	$rowEnds: (
		'rowEnd-1': 1,
		'rowEnd-2': 2,
		'rowEnd-3': 3,
		'rowEnd-4': 4,
		'rowEnd-5': 5,
		'rowEnd-6': 6,
		'rowEnd-7': 7,
		'rowEnd-8': 8,
		'rowEnd-9': 9,
		'rowEnd-10': 10,
		'rowEnd-11': 11,
		'rowEnd-12': 12,
		'rowEnd-13': 13,
	);
	grid-row-start: var(--griditem-rowstart-base);
	grid-row-end: var(--griditem-rowend-base);
	grid-column-start: var(--griditem-colstart-base);
	grid-column-end: var(--griditem-colend-base);

	@each $size, $minWidth in $sizes {
		$colend: var(--griditem-colend-#{$size}, $colend);
		$colstart: var(--griditem-colstart-#{$size}, $colstart);
		$rowend: var(--griditem-rowend-#{$size}, $rowend);
		$rowstart: var(--griditem-rowstart-#{$size}, $rowstart);

		@media (min-width: $minWidth) {
			grid-row-start: $rowstart;
			grid-row-end: $rowend;
			grid-column-start: $colstart;
			grid-column-end: $colend;
		}
	}

	@each $start, $colStart in $colStarts {
		&__#{$start} {
			grid-column-start: $colStart;
		}
	}

	@each $end, $colEnd in $colEnds {
		&__#{$end} {
			grid-column-end: $colEnd;
		}
	}

	@each $start, $rowStart in $rowStarts {
		&__#{$start} {
			grid-row-start: $rowStart;
		}
	}

	@each $end, $rowEnd in $rowEnds {
		&__#{$end} {
			grid-row-end: $rowEnd;
		}
	}
}
