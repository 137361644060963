@use 'src/styles/variables' as variables;

.imageGroup {
	margin-bottom: var(--space-stack-lg);
}

.additionalText {
	margin-top: var(--space-stack-xs);

	@media (min-width: variables.$breakpoint-lg) {
		width: 50%;
	}
}
