.pagination {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;

	.pageCounter {
		margin-bottom: 1px;
	}

	button {
		display: flex;
		align-items: center;
		border: none;
		background: none;
	}

	.icon {
		height: 1rem;
	}
}
