@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: var(--space-stack-xl);
	color: var(--text-heading-color);

	@media (min-width: variables.$breakpoint-lg) {
		&.firstCard {
			border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
		}

		&.lastCard {
			border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
		}
	}

	@media (max-width: variables.$breakpoint-lg) {
		&.firstCard {
			border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
		}

		&.lastCard {
			border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
		}
	}
}

.cisionFormContainer,
.upsalesFormContainer {
	min-height: 396px;
}
.content {
	margin-bottom: var(--space-stack-xl);
}

.footer {
	display: flex;
	justify-content: space-between;
}

.checkboxes {
	margin-bottom: var(--space-stack-xl);
}

.feedbackContainer {
	height: 100%;

	.headingContainer {
		display: flex;
		align-content: center;
		margin-bottom: var(--space-stack-xs);

		.feedbackTitle {
			margin: 0;
			line-height: 2;
		}
	}
	.icon {
		margin-right: 0.5rem;
	}
}

.error {
	margin-bottom: var(--space-stack-lg);
	color: var(--red-400);
}

.cisionForm {
	.fieldset {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		padding-bottom: var(--space-stack-lg);
	}

	.option {
		margin-bottom: var(--space-stack-xs);
	}

	.textField {
		width: 100%;
	}

	.submitButton {
		margin-bottom: var(--space-stack-md);
	}
}

.submitButton {
	display: inline-block;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	border: none;
	border-radius: var(--border-radius-4xl);
	background-color: var(--primary-green);
	padding: 0.5rem 2rem;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	min-width: 5rem;
	color: var(--text-light);
	font-weight: 600;
	font-size: 1rem;
	text-align: center;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:link,
	&:visited,
	&:hover,
	&:active {
		color: var(--text-light);
	}
}

.theme--yellow {
	background-color: var(--custom-yellow-light);
}

.theme--dark-yellow {
	background-color: var(--primary-yellow-25);
}

.theme--white {
	padding: 0;
}
