@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.articleContainer {
	--body-color: var(--text-dark);
	margin: 0 auto;
	max-width: 53.75rem;

	@media (max-width: variables.$breakpoint-max) {
		max-width: 50rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		padding: var(--space-inline-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-xl);
		padding: var(--space-inline-md);
	}
}

.mainContent {
	margin: 0 auto;
	max-width: functions.rem(925px);
}

.introduction {
	margin-top: var(--space-stack-md);
}

.longText {
	padding-top: 0;
	overflow: hidden;
}

.longText,
.content {
	h2 {
		margin-top: var(--space-stack-3xl);
	}

	h3 {
		margin-top: var(--space-stack-2xl);
	}

	h4 {
		margin-top: var(--space-stack-xl);
	}
}

.fileList {
	margin: 0;
	padding: 0;
	list-style-type: none;

	.listItem {
		display: flex;
		align-items: center;
		margin-bottom: var(--space-stack-sm);

		.icon {
			margin-right: var(--space-inline-md);
		}
	}
}

.icon {
	align-self: baseline;
	width: 1.25rem;
	height: 1.5rem;
	font-size: 1.5rem;
}
