.integrationCardLink {
	color: var(--text-dark);
	font-weight: normal;
}

.fullAppCard {
	display: block;
	cursor: pointer;
	width: 100%;

	.fullAppCardTop {
		display: flex;
		overflow: hidden;

		&:hover {
			border-color: var(--selected-grey-border) !important;
		}

		.header {
			display: flex;
			gap: var(--space-inset-lg);
		}

		.summary {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			line-clamp: 3;
			display: -webkit-box;
			height: calc(#{var(--line-height-body)} * 3);
			overflow: hidden;
			font-size: var(--font-size-small);
			text-overflow: ellipsis;
		}

		.companyName {
			text-transform: uppercase;
			white-space: nowrap !important;
		}
	}
}

.categories {
	display: flex;
	flex-basis: 100%;
	flex-wrap: wrap;
	align-items: center;
	font-size: var(--font-size-small);

	svg {
		margin-right: var(--space-inline-xs);
	}
	div {
		display: flex;

		&::after {
			margin-right: var(--space-inline-2xs);
			content: ',';
		}

		&:last-of-type::after {
			content: '';
		}
	}
}

.integrationCardHeading {
	display: flex;
	column-gap: var(--space-inline-xl);
	row-gap: var(--space-stack-xs);
	flex-grow: 1;
	flex-direction: column;
}

.integrationCardHead {
	flex-basis: 100%;
}

.insightsBlock {
	display: flex;
	flex-wrap: wrap;
	gap: var(--space-inset-xs);
}

.ratings {
	display: flex;
	column-gap: var(--space-inline-xs);
	align-items: center;
	font-size: var(--font-size-small);
}
.sizeOfCompany {
	display: flex;
	column-gap: var(--space-inline-xs);
	align-items: center;
	font-size: var(--font-size-small);
}
