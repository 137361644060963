.brandColor {
	--brand-stripe-1-bg: var(--primary-yellow);
}

.noBackgroundColor {
	background-color: transparent;
}

.group {
	display: flex;
	flex-direction: column;
	gap: 5rem;
}
