.divider {
	background: var(--background);
	width: 100%;
	height: var(--divider-height);
}

.margin--xs {
	margin: var(--space-stack-xs) 0;
}

.margin--sm {
	margin: var(--space-stack-sm) 0;
}

.margin--md {
	margin: var(--space-stack-md) 0;
}

.margin--lg {
	margin: var(--space-stack-lg) 0;
}

.margin--xl {
	margin: var(--space-stack-xl) 0;
}

.margin--2xl {
	margin: var(--space-stack-2xl) 0;
}

.margin--3xl {
	margin: var(--space-stack-3xl) 0;
}
