@use 'src/styles/variables' as variables;

.headerContainer {
	margin-bottom: var(--space-stack-2xl);
	padding-top: var(--space-inset-3xl);
	padding-right: var(--space-stack-3xl);
	padding-left: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-sm) {
		padding-top: 0;
	}

	@media (max-width: variables.$breakpoint-md) {
		padding-right: var(--space-stack-xl);
		padding-left: var(--space-stack-xl);
	}
}

.image {
	position: relative;

	@media (max-width: variables.$breakpoint-sm) {
		display: flex;
		margin: 0 auto;
		width: 82%;
	}
}

.title {
	max-width: var(--text-max-width);
	line-height: 1.1;
}

.buttons {
	grid-column: span 2;
	align-self: flex-end;
	justify-self: flex-end;
	@media (min-width: 75rem) {
		margin-bottom: var(--space-stack-3xl);
		margin-left: var(--space-stack-3xl);
	}
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 4rem 0;
}

.foldoutButton {
	margin-top: var(--space-stack-xl);
	text-align: center;
}
