@use 'src/styles/variables' as variables;

.container {
	@media (max-width: variables.$breakpoint-lg) {
		padding: 0 var(--space-inset-lg);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-md);
	}
}

.products.products.products {
	padding: var(--space-inset-3xl) var(--space-inset-5xl);

	ul {
		li {
			margin-bottom: 0;
			height: 3.4rem;
		}
	}

	@media (max-width: variables.$breakpoint-xl) {
		padding: var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl) var(--space-inset-md);
	}
}

.headerContainer {
	padding: var(--space-inset-3xl) var(--space-inset-5xl);
	height: 100%;

	@media (max-width: variables.$breakpoint-xl) {
		padding: var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl) var(--space-inset-md);
	}
}

.link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 0;
	height: 3.4rem;
}
