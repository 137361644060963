.contactInformation {
	border-radius: var(--border-radius-lg);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-xl);
}

.contactMethod {
	display: flex;
	align-items: center;
	gap: var(--space-inset-md);
	height: var(--space-inset-xl);
}

.showAccessRights {
	margin-top: var(--space-stack-lg);
}
