.scopeList {
	padding: var(--space-inline-sm);

	ul {
		text-align: left;

		li {
			list-style-position: inside;
		}
	}
}

.scopeTooltip {
	display: inline !important;
	vertical-align: bottom;
}
