@use 'src/styles/variables' as variables;

.geolocationError {
	color: var(--danger-color);
}

.footer {
	margin-top: var(--space-stack-xl);
	padding: var(--space-inline-xl) var(--space-inline-2xl);
	text-align: center;

	.brandLine {
		--brand-stripe-1-bg: var(--text-yellow);
		margin: var(--space-stack-lg) auto;
		width: 50%;
		text-align: center;
	}

	.gridContainer {
		margin: 0 auto;
		width: 50%;
		text-align: center;
		a {
			margin-right: 0;
		}
	}
}

.filterContainer {
	border-radius: var(--border-radius-md);
	background-color: var(--bg-dark-green-extra-light);
	padding: var(--space-inset-lg);
	padding-top: var(--space-inset-xl);
	width: 100%;

	.searchFieldContainer {
		.searchButton {
			margin-top: var(--space-stack-2xs);
			margin-left: var(--space-stack-xs);
			.button {
				width: 5rem;
			}
		}
		.searchField {
			width: 100%;
		}
		.iconContainer {
			margin-top: var(--space-stack-sm);
			margin-left: var(--space-stack-xs);
		}
		.gridItem {
			display: flex;
		}
		.selectDistance {
			margin-bottom: var(--space-stack-sm);
			min-width: 13rem;
		}
	}
	.showAllContainer {
		display: flex;
		border: 1px solid var(--border-color-light);
		border-radius: var(--border-radius-md);
		padding: var(--space-inset-md);
		width: 100%;

		.showAllText {
			margin-left: 2.5rem;
		}
		.mapIcon {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: var(--space-stack-md);
		}
	}

	.multiChoiceContainer {
		margin-top: var(--space-stack-md);
		border-top: 1px solid var(--border-color-light);
		padding-top: var(--space-stack-lg);
	}
}

.accountantCards {
	margin-top: var(--space-inset-2xl);
}
.paginationTop {
	margin-bottom: var(--space-stack-md);
}

.paginationBottom {
	margin-top: var(--space-stack-md);
}

.cardContainer {
	> div {
		padding: var(--space-inset-xl) var(--space-inset-lg) var(--space-inset-lg);
	}
}

.hasSubMenuV1 {
	@media (min-width: variables.$breakpoint-md) {
		.filterContainer {
			width: 70%;
		}

		.footer {
			.gridContainer {
				width: 70%;
			}
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.footer {
		.gridContainer {
			width: 100%;
		}
	}
	.filterContainer {
		padding: var(--space-inset-sm);
		padding-top: var(--space-inset-xl);
	}
}
